<template>
    <a-modal v-model="filterVisible" title="指数平滑" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="indeptIndex">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选方法：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="selectedMethod">
                        <a-select-option v-for="item in methods" :key="item.methodId" :value="item.methodId">{{item.methodName}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选范围：</div></a-col>
                <a-col flex="auto">
                    <a-row>
                        <a-col :span="8">
                            <a-select style="width:120px" v-model="startSection">
                                <a-select-option v-for="item in times" :key="item.timeCode" :value="item.timeCode">{{item.name}}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="4" style="text-align:center">——</a-col>
                        <a-col :span="8">
                            <a-select style="width:120px" v-model="endSection">
                                <a-select-option v-for="item in times" :key="item.timeCode" :value="item.timeCode">{{item.name}}</a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">预测到：</div></a-col>
                <a-col flex="auto">
                    <a-row>
                        <a-col :span="8">
                            <a-select style="width:120px" v-model="forecastSection">
                                <a-select-option v-for="item in forcastTimes" :key="item.timeCode" :value="item.timeCode">{{item.name}}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="16">
                            预测区间置信水平
                            <a-select style="width:70px" v-model="fmcl">
                                <a-select-option :value="0.90">90%</a-select-option>
                                <a-select-option :value="0.95">95%</a-select-option>
                                <a-select-option :value="0.99">99%</a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <div class="box">
                            <div class="name">平滑参数</div>
                            <div class="checkbox">
                                <a-checkbox-group v-model="smoothParams">
                                    <div class="item">
                                        <div class="item-inner">
                                            <a-checkbox value="alpha">Alpha</a-checkbox>
                                        </div>
                                        <a-input-number size="small" v-model="alpha" :step="0.1" :min="0" :max="1" />
                                    </div>
                                    <div class="item">
                                        <div class="item-inner">
                                            <a-checkbox value="beta">Beta</a-checkbox>
                                        </div>
                                        <a-input-number size="small" v-model="beta" :step="0.1" :min="0" :max="1" />
                                    </div>
                                    <div class="item">
                                        <div class="item-inner">
                                            <a-checkbox value="gamma">Gamma</a-checkbox>
                                        </div>
                                        <a-input-number size="small" v-model="gamma" :step="0.1" :min="0" :max="1" />
                                    </div>                           
                                </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="12">                
                        <div class="box">
                            <div class="name">因变量转换</div>
                            <div class="checkbox">
                                <a-radio-group v-model="deptTrans">
                                    <div class="item">
                                        <a-radio value="NONE">无</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio value="SQRT">平方根</a-radio>
                                    </div>    
                                    <div class="item">
                                        <a-radio value="LOG">自然对数</a-radio>
                                    </div>                          
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { getForcastTime } from '../../../until/data_tools';
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId','endYear','freqId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            methods:[],
            times:[],
            //forcastTimes:[],
            selectedMethod:null,
            indeptIndex:null,
            startSection:null,
            endSection:null,
            forecastSection:null,
            deptTrans:'NONE',
            fmcl:0.90,
            smoothParams:[],
            alpha:null,
            beta:null,
            gamma:null,
            spinning:false
        }
    },
    computed:{
        forcastTimes() {
            let arr = [];
            if(this.times.length>0) {
                arr = getForcastTime(this.freqId,this.times[this.times.length-1].timeCode); 
            }
            return [...arr];
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.selectedMethod = null;
                this.indeptIndex = null;
                this.startSection = null;
                this.endSection = null;
                this.forecastSection = null;
                this.deptTrans = 'NONE';
                this.fmcl = 0.90;
                this.smoothParams = [];
                this.alpha = null;
                this.beta = null;
                this.gamma = null;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'expsmooth',sheetId:this.sheetId}).then(res=>{
                    if(res.indeptVars) {
                        this.list = res.indeptVars;
                        this.methods = res.methods;
                        this.selectedMethod = res.methods[0].methodId;
                        this.times = res.sampleSections;
                    }
                });
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(this.indeptIndex===null) {
                messageTips('请选择变量',2);
                return false;
            }
            if(this.startSection===null||this.endSection===null) {
                messageTips('请选择起止时间',2);
                return false;
            }
            if(this.forecastSection===null) {
                messageTips('请选择预测时间',2);
                return false;
            }
            let params = {
				indeptIndex:this.indeptIndex,
				startSection:this.startSection,
				endSection:this.endSection,
				forecastSection:this.forecastSection,
				fmcl:this.fmcl,
				method:this.selectedMethod,
				deptTrans:this.deptTrans,
				sheetId:this.sheetId
			};
			if(this.smoothParams.indexOf('alpha')!==-1&&this.alpha) {
				params.alpha = this.alpha;
			}
			if(this.smoothParams.indexOf('alpha')!==-1&&this.beta) {
				params.beta = this.beta;
			}
			if(this.smoothParams.indexOf('alpha')!==-1&&this.gamma) {
				params.gamma = this.gamma;
            }
            this.spinning = true;
            this.$http.cloud_set_expsmooth(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.success===false) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.$emit('refreshData',true);
                    this.filterVisible = false;
                    var result = {'analysis_expsmooth':res}
                    setSessionItem('analysis_expsmooth',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_expsmooth'});
                    window.open(href, '_blank');
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }


    .other {
        margin-top: 20px;
    }
    .model-item {
        height:32px;
        line-height: 32px;
    }

    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }

</style>
<template>
    <a-modal v-model="filterVisible" title="单位根检验" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="indexs">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <div class="box">
                            <div class="name">检验类型</div>
                            <div class="checkbox">
                                <a-radio-group v-model="method" @change="changeMethod">
                                    <div class="item">
                                        <a-radio :value="1">ADF检验</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="2">pp检验</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="3">kpss</a-radio>
                                    </div>                           
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="12">                
                        <div class="box">
                            <div class="name">检验对象</div>
                            <div class="checkbox">
                                <a-radio-group v-model="objType">
                                    <div class="item">
                                        <a-radio :value="1">水平序列</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="2">一阶差分序列</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="3">二阶差分序列</a-radio>
                                    </div>                           
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="24">
                        <div class="box">
                            <div class="name">模型附加项</div>
                            <div class="checkbox">
                                <a-radio-group v-model="type">
                                    <a-radio :value="1" :disabled="method!==1">无</a-radio>
                                    <a-radio :value="2">截距项</a-radio>
                                    <a-radio :value="3">趋势项</a-radio>                          
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="24">
                        <div class="box">
                            <div class="name">模型附加项</div>
                            <div class="checkbox">
                                <a-radio-group v-model="lagType">
                                    <div class="item">
                                        <a-radio :value="1" :disabled="method!==1">自动选择
                                            <a-select v-model="lagTypes" size="small">
                                                <a-select-option value="AIC">AIC</a-select-option>
                                                <a-select-option value="BIC">BIC</a-select-option>
                                            </a-select>
                                            最大值
                                            <a-input-number :min="0" :max="20" size="small" v-model="lags" />
                                        </a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="2">自定义
                                            <a-input-number :min="0" :max="20" size="small" v-model="lags2" />
                                        </a-radio>
                                    </div>                       
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            indexs:null,
            lagTypes:'AIC',
            method:1,
            objType:1,
            type:1,
            lagType:1,
            lags:null,
            lags2:null,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.indexs = null;
                this.lagTypes = 'AIC';
                this.method = 1;
                this.objType = 1;
                this.type = 1;
                this.lagType = 1;
                this.lags = null;
                this.lags2 = null;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },
        changeMethod(e) {
            if(e.target.value!==1) {
                if(this.type===1) {
                    this.type = 2;
                }
                if(this.lagType===1) {
                    this.lagType = 2;
                }
            }
        },

        goApply() {
            if(this.indexs===null) {
                messageTips('请选择变量',2);
                return false;
            }
            let params = {
                indexs:this.indexs,
                method:this.method,
                objType:this.objType,
                type:this.type,
                lagType:this.lagType,
                sheetId:this.sheetId
            }
            if(this.lagType===1) {
                params.lags = this.lags;
            }else if(this.lagType===2) {
                params.lags = this.lags2;
            }
            this.spinning = true;
            this.$http.cloud_set_unitRoot(params).then(res=>{
                this.spinning = false;
                if(!res.success) {
                    messageTips(res.message,1);
                    return false;
                }
                this.filterVisible = false;
                let result = {'analysis_unitCheck':res}
                setSessionItem('analysis_unitCheck',JSON.stringify(result));
                let {href} = this.$router.resolve({path: '/analysis_unitCheck'});
                window.open(href, '_blank');
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }
</style>
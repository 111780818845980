<template>
    <a-modal v-model="filterVisible" title="缺省值处理" :width="500" :footer="null" :centered="true">
        <div class="variables">
            <div class="h2">选择变量：<a-checkbox @click="selectAll" :indeterminate="checkedList.length>0&&checkedList.length<list.length" :checked="checkedList.length===list.length&&list.length>0">全选</a-checkbox></div>
            <div class="list">
                <a-checkbox-group v-model="checkedList">
                    <div class="check-item" v-for="item in list" :key="item.index">
                        <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                    </div>
                </a-checkbox-group>
            </div>
        </div>
        <div class="ways">
            <div class="h2">方法</div>
            <div class="list">
                <a-radio-group style="width:100%" v-model="selectedWay">
                    <a-row :gutter="[16,12]">
                        <a-col :span="10">
                            <a-radio value="avg">序列均值</a-radio>
                        </a-col>
                        <a-col :span="14">
                            <a-radio value="lv">前一个值</a-radio>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,12]">
                        <a-col :span="10">
                            <a-radio value="cis">三次样条内差</a-radio>
                        </a-col>
                        <a-col :span="14">
                            <a-radio value="nv">后一个值</a-radio>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,12]">
                        <a-col :span="10">
                            <a-radio value="geo">几何插补</a-radio>
                        </a-col>
                        <a-col :span="14">
                            <a-radio value="nmean">相邻N点均值
                                <a-input-number size="small" v-model="number1" />
                            </a-radio>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,12]">
                        <a-col :span="10">
                            <a-radio value="li">线性插值</a-radio>
                        </a-col>
                        <a-col :span="14">
                            <a-radio value="nmedian">相邻N点中位数
                                <a-input-number size="small" v-model="number2" />
                            </a-radio>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[16,12]">
                        <a-col :span="10">
                            <a-radio value="lt">线性趋势</a-radio>
                        </a-col>
                        <a-col :span="14">
                            <a-radio value="random">随机值</a-radio>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,12]">
                        <a-col :span="24">
                            <a-radio value="self">自定义值
                                <a-input size="small" v-model="value" />
                            </a-radio>
                            <div class="red">
                                *请输入数据，数据添加顺序从左到右，从上到下进行，数据间用英文半逗号隔开
                            </div>
                        </a-col>
                    </a-row>
                </a-radio-group>
            </div>
        </div>
        <div class="btns">
            <a-button type="primary" ghost @click="cancel">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            selectedWay:null,
            number1:null,
            number2:null,
            value:null,
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.checkedList = [];
                this.selectedWay = null;
                this.number1 = null;
                this.number2 = null;
                this.value = null;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },
        selectAll() {
            if(this.checkedList.length === this.list.length) {
                this.checkedList = [];
            } else {
                this.list.forEach(item=>{
                    let index = this.checkedList.indexOf(item.index);
                    if(index===-1) {
                        this.checkedList.push(item.index);
                    }
                });
            }            
        },
        goApply() {
            if(this.checkedList.length===0) {
                messageTips('请选择变量',2);
                return false;
            }
            if(!this.selectedWay) {
                messageTips('请选择方法',2);
                return false;
            }
            let params = {
                action:this.selectedWay,
                indexs:this.checkedList.join(','),
                sheetId:this.sheetId,
            }
            if(this.selectedWay==='nmean') {
                if(!this.number1) {
                    messageTips('请输入相邻N点均值:N值',2);
                    return false;
                } else{
                    params.n = this.number1;
                }
            } else if(this.selectedWay==='nmedian') {
                if(!this.number2) {
                    messageTips('相邻N点中位数:N值',2);
                    return false;
                } else{
                    params.n = this.number2;
                }
            } else if(this.selectedWay==='self') {
                params.value = this.value;
            }
            this.$emit('setMissvalueResult',{data:params});
            this.filterVisible = false;
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
    .other {
        padding-top: 20px;
    }
    .red {
        color:#f00;
        font-size: 12px;
        margin-left:18px;
    }
</style>
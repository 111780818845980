<template>
    <a-spin :spinning="spinning" tip="正在请求，请稍候……">
        <a-layout style="height:100%">
            <a-layout-sider :style="pageLayoutSiderStyle">
                <div class="sider-inner" :style="{width:showSide?'100%':'10px'}">
                    <div class="switch" @click="switchSider">
                        <a-icon type="backward" v-if="showSide" />
                        <a-icon type="forward" v-else />
                    </div>
                    <div class="dimension" v-show="showSide">
                        <a-tree-select
                            v-model="selectedReport"
                            style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            placeholder="Please select"
                            :treeDefaultExpandedKeys="['a-1','b-1']"
                            @change="changeReport"
                        >
                            <a-icon slot="suffixIcon" type="caret-down" />
                            <a-tree-select-node key="a-1" value="a-1" title="我的数据" :selectable="false">
                                <a-tree-select-node :selectable="false" :value="1+'-'+key" :title="item.title+'（'+item.children.length+'）'" v-for="(item,key) in series" :key="1+'-'+key">                                    
                                    <a-tree-select-node :key="'1-'+i.freq_ID+'-'+i.cube_ID" :value="'1-'+i.freq_ID+'-'+i.cube_ID" :title="i.cube_NAME" v-for="i in item.children" />
                                </a-tree-select-node>
                            </a-tree-select-node>
                            <a-tree-select-node key="b-1" value="b-1" title="我的上传" :selectable="false">
                                <a-tree-select-node :selectable="false" :value="2+'-'+key" :title="item.title+'（'+item.children.length+'）'" v-for="(item,key) in myDatas" :key="2+'-'+key">                                    
                                    <a-tree-select-node :key="'2-'+i.freq_ID+'-'+i.cube_ID" :value="'2-'+i.freq_ID+'-'+i.cube_ID" :title="i.cube_NAME" v-for="i in item.children" />
                                </a-tree-select-node>
                            </a-tree-select-node>
                        </a-tree-select>

                        <div class="h2">选择序列</div>
                        <a-dropdown :trigger="['click']">
                            <div class="select-wrap" @click="e => e.preventDefault()">
                                <div class="selected">序列（已选择{{selectedIndicators.length}}）</div>
                                <a-icon type="caret-down" />
                            </div>
                            <a-menu slot="overlay">                                                             
                                <a-menu-item class="drop-list-item" key="a1">
                                    <a-checkbox
                                    @click.stop="selectAllSeries" 
                                    :indeterminate="indicators.length>selectedIndicators.length&&selectedIndicators.length>0" 
                                    :checked="indicators.length===selectedIndicators.length&&selectedIndicators.length>0">
                                        <span @click.stop>全选</span>
                                    </a-checkbox>
                                </a-menu-item>
                                <a-checkbox-group v-model="selectedIndicators">       
                                    <a-menu-item class="drop-list-item" v-for="item in indicators" :key="item.dimCode">
                                        <a-checkbox :value="item.dimCode">{{item.dimName}}</a-checkbox>
                                    </a-menu-item>
                                </a-checkbox-group>
                            </a-menu>
                        </a-dropdown>

                        <div class="h2">起止时间</div>
                        <div v-if="freqId===1">
                            <a-select default-value="开始时间" style="width: 100px" v-model="startYear">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option v-for="(i,k) in times" :key="i.dimCode" :value="k">
                                    {{i.dimName}}
                                </a-select-option>
                            </a-select>
                            <span class="split">~</span>
                            <a-select default-value="结束时间" style="width: 100px" v-model="endYear">
                                <a-icon slot="suffixIcon" type="caret-down" />       
                                <a-select-option v-for="(i,k) in times" :key="i.dimCode" :value="k">
                                    {{i.dimName}}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div v-else>
                            <a-select default-value="开始时间" style="width: 100px" v-model="startYear">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-opt-group v-for="(item,key) in times" :key="item.dimCode">
                                    <span slot="label">{{item.dimName}}</span>
                                    <a-select-option v-for="(i,k) in item.children" :key="i.dimCode" :value="key+'-'+k">
                                        {{i.dimName}}
                                    </a-select-option>
                                </a-select-opt-group>
                            </a-select>
                            <span class="split">~</span>
                            <a-select default-value="结束时间" style="width: 100px" v-model="endYear">
                                <a-icon slot="suffixIcon" type="caret-down" />       
                                <a-select-opt-group v-for="(item,key) in times" :key="item.dimCode">
                                    <span slot="label">{{item.dimName}}</span>
                                    <a-select-option v-for="(i,k) in item.children" :key="i.dimCode" :value="key+'-'+k">
                                        {{i.dimName}}
                                    </a-select-option>
                                </a-select-opt-group>
                            </a-select>
                        </div>

                        <div class="sync-data-btn" @click="goSync()">
                           
                            <span class="iconfont icon-sousuo"></span>
                            查询
                        </div>
                    </div>
                </div>
            </a-layout-sider>
            <a-layout-content>
                <div class="table-chart">
                    <div class="table-area" :style="tableAreaStyle">
                        <div class="toolbar">
                            <a-tabs type="card">
                                <a-tab-pane key="1" tab="数据预处理">
                                    <ul>
                                        <li @click="showModal('descStatistics')">描述性统计</li>
                                        <li @click="showModal('growthRate')">增长率</li>
                                        <li @click="showModal('log')">对数</li>
                                        <li @click="showModal('diff')">差分</li>
                                        <li @click="showModal('lag')">滞后</li>
                                        <li @click="showModal('aggregation')">时间聚合</li>
                                        <li @click="showModal('missvalue')">缺省值处理</li>
                                        <li @click="showModal('userFunction')">自定义函数</li>
                                    </ul>
                                    <ul class="usualy">
                                        <li @click="clearResult">清除计算结果</li>
                                        <li @click="selectUploadType">上传</li>
                                        <li @click="downloadTable">下载</li>
                                    </ul>
                                    <div class="clear"></div>
                                </a-tab-pane>
                                <a-tab-pane key="2" tab="相关性分析">
                                    <ul>
                                        <li @click="showModal('bivar')">双变量</li>
                                        <li @click="showModal('partial')">偏相关</li>
                                    </ul>
                                    <ul class="usualy">
                                        <li @click="clearResult">清除计算结果</li>
                                        <li @click="selectUploadType">上传</li>
                                        <li @click="downloadTable">下载</li>
                                    </ul>
                                    <div class="clear"></div>
                                </a-tab-pane>
                                <a-tab-pane key="3" tab="回归">
                                    <ul>
                                        <li @click="showModal('lineReturn')">线性回归</li>
                                        <li @click="showModal('curvefit')">曲线估计</li>
                                        <li @click="showModal('leastSquares')">二阶段最小二乘</li>
                                    </ul>
                                    <ul class="usualy">
                                        <li @click="clearResult">清除计算结果</li>
                                        <li @click="selectUploadType">上传</li>
                                        <li @click="downloadTable">下载</li>
                                    </ul>
                                    <div class="clear"></div>
                                </a-tab-pane>
                                <a-tab-pane key="4" tab="时间序列分析">
                                    <ul>
                                        <li @click="showModal('autocorre')">自相关分析</li>
                                        <li @click="showModal('hpFilter')">H-P滤波</li>
                                        <li @click="showModal('expsmooth')">指数平滑</li>
                                        <li @click="showModal('arima')">ARIMA</li>
                                    </ul>
                                    <ul class="usualy">
                                        <li @click="clearResult">清除计算结果</li>
                                        <li @click="selectUploadType">上传</li>
                                        <li @click="downloadTable">下载</li>
                                    </ul>
                                    <div class="clear"></div>
                                </a-tab-pane>
                                <a-tab-pane key="5" tab="计量经济工具箱">
                                    <ul>
                                        <li @click="showModal('unitRoot')">单位根检验</li>
                                        <li @click="showModal('grangerCheck')">格兰杰因果检验</li>
                                        <li @click="showModal('varModel')">VAR模型</li>
                                        <li @click="showModal('Johansen')">Johansen协整检验</li>
                                        <li @click="showModal('EngleGranger')">Engle-Granger协整分析</li>
                                        <li @click="showModal('arch')">ARCH效应检验</li>
                                        <li @click="showModal('garch')">GARCH模型</li>
                                    </ul>
                                    <ul class="usualy">
                                        <li @click="clearResult">清除计算结果</li>
                                        <li @click="selectUploadType">上传</li>
                                        <li @click="downloadTable">下载</li>
                                    </ul>
                                    <div class="clear"></div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                        <div class="handsontable-container data-table" ref="handsontable"></div>
                    </div>

                    <div class="chart-area" :style="chartAreaStyle">
                        <div class="toolbar">
                            <div class="ctrl-btn">
                                <div class="item" :class="{active:chartType==='line'}" @click="createChart('line')">
                                    <span class="iconfont icon-zhexiantu"></span>
                                    <div class="name">折线图</div>
                                </div>
                                <div class="item" :class="{active:chartType==='bar'}" @click="createChart('bar')">
                                    <span class="iconfont icon-zhuzhuangtu"></span>
                                    <div class="name">柱状图</div>
                                </div>
                                <div class="item" :class="{active:chartType==='area'}" @click="createChart('area')">
                                    <span class="iconfont icon-duijimianjitu"></span>
                                    <div class="name">面积图</div>
                                </div>
                                <div class="item" :class="{active:chartType==='scatter'}" @click="scatterFn">
                                    <span class="iconfont icon-tubiaoleisandiantu"></span>
                                    <div class="name">散点图</div>
                                </div>
                                <div class="item" :class="{'active':showChartLegend}" @click="showChartLegendFn">
                                    <span class="iconfont icon-tuli"></span>
                                    <div class="name">图例</div>
                                </div>
                                <div class="item wide" :class="{active:chartType===null}" @click="userChartFn">
                                    <span class="iconfont icon-zidingyi"></span>
                                    <div class="name">自定义图表</div>
                                </div>
                                <div class="item" @click="downloadImg(1)">
                                    <span class="iconfont icon-xiazai"></span>
                                    <div class="name">下载</div>
                                </div>
                            </div>
                            <div class="size-btn">
                                <span class="iconfont icon-zuixiaohua" title="最小化" v-if="chartAreaSize!==2" @click="chartAreaSize=2"></span>
                                <span class="iconfont icon-double-square" title="还原" v-if="chartAreaSize!==1" @click="chartAreaSize=1"></span>
                                <span class="iconfont icon-zuidahua_huaban1" title="最大化" v-if="chartAreaSize!==3" @click="checkBigChart"></span>
                            </div>
                            <div class="bar-cover" v-if="!hotRef" v-show="chartAreaSize!==2"></div>
                        </div>
                        <div class="chart" ref="chart"></div>
                    </div>
                </div>

                <div class="big-chart" v-show="bigChartVisible">
                    <div class="toolbar">
                        <div class="ctrl-btn">
                            <div class="item" :class="{active:chartType==='line'}" @click="createBigChart('line')">
                                <span class="iconfont icon-zhexiantu"></span>
                                <div class="name">折线图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='bar'}" @click="createBigChart('bar')">
                                <span class="iconfont icon-zhuzhuangtu"></span>
                                <div class="name">柱状图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='area'}" @click="createBigChart('area')">
                                <span class="iconfont icon-duijimianjitu"></span>
                                <div class="name">面积图</div>
                            </div>

                            <div class="item" :class="{active:chartType==='scatter'}" @click="scatterFn">
                                <span class="iconfont icon-tubiaoleisandiantu"></span>
                                <div class="name">散点图</div>
                            </div>

                            <div class="item" :class="{'active':showChartLegend}" @click="showChartLegendFn">
                                <span class="iconfont icon-tuli"></span>
                                <div class="name">图例</div>
                            </div>
                            <div class="item wide" :class="{active:chartType===null}" @click="userChartFn">
                                <span class="iconfont icon-zidingyi"></span>
                                <div class="name">自定义图表</div>
                            </div>
                            <div class="item" @click="downloadImg(2)">
                                <span class="iconfont icon-xiazai"></span>
                                <div class="name">下载</div>
                            </div>
                        </div>
                        <div class="size-btn">
                            <a-icon type="fullscreen-exit" @click="closeBigChart" />
                        </div>
                    </div>
                    <div class="chart-dims">
                        <a-select style="width:100%;height:32px;overflow:hidden" mode="multiple" placeholder="请选择序列" v-model="selectedRows" @change="changeSeries">
                            <a-select-option  v-for="(item) in rows" :key="item.index" :value="item.index">{{item.key}}</a-select-option>
                        </a-select>
                    </div>
                    <div class="chart" ref="bigChart">

                    </div>
                </div>
            </a-layout-content>

            <desc-statistics :visible="DescStatisticsVisible" :sheetId="sheetId" @setDescResult="setDescResult" />
            <growth-rate :visible="GrowthRateVisible" :sheetId="sheetId" @growthRate="setGrowthRate" />
            <log :visible="logVisibile" :sheetId="sheetId" @setLogResult="setLogResult" />
            <diff :visible="difVisibile" :sheetId="sheetId" @setDiffResult="setDiffResult" />
            <lag :visible="lagVisibile" :sheetId="sheetId" @setLagResutl="setLagResutl" />
            <aggregation :visible="aggregationVisible" :sheetId="sheetId" @setAggregationResult="setAggregationResult" />
            <missvalue  :visible="missValueVisibile" :sheetId="sheetId" @setMissvalueResult="setMissvalueResult" />
            <user-function  :visible="UserFunctionVisibile" :sheetId="sheetId" @userFunction="setUserFunction" />
            <bivar :visible="bivarVisibile" :sheetId="sheetId" />
            <partial :visible="partialVisibile" :sheetId="sheetId" />
            <line-return :visible="LineReturnVisibile" :sheetId="sheetId" @refreshData="refreshData" />
            <curvefit :visible="curvefitVisibile" :sheetId="sheetId" @refreshData="refreshData" />
            <least-squares :visible="leastSquaresVisibile" :sheetId="sheetId" @refreshData="refreshData" />
            <autocorre :visible="autocorreVisibile" :sheetId="sheetId" />
            <h-p-filter :visible="HPFilterVisibile" :sheetId="sheetId" @refreshData="refreshData" />
            <expsmooth :visible="expsmoothVisibile" :sheetId="sheetId" :endYear="endYear" :freqId="freqId" @refreshData="refreshData" />
            <arima :visible="arimaVisibile" :sheetId="sheetId" :endYear="endYear" :freqId="freqId" @refreshData="refreshData" />
            <unit-root :visible="UnitRootVisibile" :sheetId="sheetId" />
            <granger-check :visible="GrangerCheckVisibile" :sheetId="sheetId" />
            <var-model :visible="VarModelVisibile" :sheetId="sheetId" :endYear="endYear" :times="times" :freqId="freqId" @refreshData="refreshData" />
            <johansen :visible="johansenVisibile" :sheetId="sheetId" />
            <engle-granger :visible="EngleGrangerVisibile" :sheetId="sheetId" @refreshData="refreshData" />
            <arch :visible="archVisibile" :sheetId="sheetId" @refreshData="refreshData" />
            <garch :visible="garchVisibile" :sheetId="sheetId" :endYear="endYear" :times="times" :freqId="freqId" @refreshData="refreshData" />
            <user-chart :visible="userChartVisible" :sheetId="sheetId" @setUserChart="setUserChart" :tableData="userChartData" :source="2" />
            <desc-statistics-result :data="descStatisticsData" :visible="DescStatisticsResultVisible" />
            <upload-mould :visible="uploadMouldVisible" :freqId="selectedUploadFreqId" @uploadSuccess="uploadSuccess" />
            <scatter-set :visible="scatterSetVisible" :sheetId="sheetId" @scatterSetCtrl="scatterSetCtrl" />
            <indicator-info :visible="indicatorInfoVisible" :params="infoParams"  />
            <a-modal v-model="uploadTypeVisible" title="我要上传" :footer="null" :centered="true">
                <div class="btns">
                    <a-row>
                        <a-col :span="8" style="text-align:center"><a-button type="primary" @click="selectUploadFreqId(1)">年度数据</a-button></a-col>
                        <a-col :span="8" style="text-align:center"><a-button type="primary" @click="selectUploadFreqId(3)">季度数据</a-button></a-col>
                        <a-col :span="8" style="text-align:center"><a-button type="primary" @click="selectUploadFreqId(4)">月度数据</a-button></a-col>
                    </a-row>
                </div>
            </a-modal>            
        </a-layout>
    </a-spin>
</template>

<script>
import axios from 'axios';
import {pageLayoutSiderStyle,pageLayoutSiderHideStyle} from '../../until/global_style';
import 'handsontable/dist/handsontable.full.css';
import Handsontable from 'handsontable';
import {createSettings, tableParse} from '../../until/handsontableSetting';
import * as echarts from 'echarts';
import {setCharts,showChartLegendFn,setBigChart} from '../../until/chart.js';
import DescStatistics from './components/DescStatistics.vue';
import GrowthRate from '../../components/GrowthRate.vue';
import Log from './components/Log.vue';
import Diff from './components/Diff.vue';
import Lag from './components/Lag.vue';
import Aggregation from './components/Aggregation.vue';
import Missvalue from './components/Missvalue.vue';
import UserFunction from '../../components/UserFunction.vue';
import Bivar from './components/Bivar.vue';
import Partial from './components/Partial.vue';
import LineReturn from './components/LineReturn.vue';
import Curvefit from './components/Curvefit.vue';
import LeastSquares from './components/LeastSquares.vue';
import Autocorre from './components/Autocorre.vue';
import HPFilter from './components/HPFilter.vue';
import Expsmooth from './components/Expsmooth.vue';
import Arima from './components/Arima.vue';
import UnitRoot from './components/UnitRoot.vue';
import GrangerCheck from './components/GrangerCheck.vue';
import VarModel from './components/VarModel.vue';
import Johansen from './components/Johansen.vue';
import EngleGranger from './components/EngleGranger.vue';
import Arch from './components/Arch.vue';
import Garch from './components/Garch.vue';
import DescStatisticsResult from './components/DescStatisticsResult.vue';
import UserChart from '../../components/UserChart.vue';
import ScatterSet from '../../components/ScatterSet.vue';
import UploadMould from './components/UploadMould.vue';
// import UserInfo from '../../components/UserInfo.vue';
import IndicatorInfo from './components/IndicatorInfo.vue';
import html2canvas from 'html2canvas';
import { getCookie, messageTips, objToUrl,savePicture } from '../../until/tools';
import config from '../../until/configs';
import { Empty } from 'ant-design-vue';
var chartHandle;
var bigChartHandle;

function sequence(a,b){
    if (a>b) {
        return 1;
    }else if(a<b){
        return -1
    }else{
        return 0;
    }
}
export default {
    components:{DescStatistics,GrowthRate,Log,Diff,Lag,Aggregation,Missvalue,UserFunction,Bivar,Partial,LineReturn,Curvefit,LeastSquares,Autocorre,HPFilter,Expsmooth,Arima,UnitRoot,GrangerCheck,VarModel,Johansen,EngleGranger,Arch,Garch,DescStatisticsResult,UserChart,ScatterSet,UploadMould,IndicatorInfo},
    data() {
        return {
            pageLayoutSiderStyle,
            pageLayoutSiderHideStyle,
            simpleImage:Empty.PRESENTED_IMAGE_SIMPLE,
            showSide:true,
            spinning:false,
            selectedReport:'',
            selectedIndicators:[],
            allSeriesLength:0,
            myDatasLength:0,
            series:[],
            myDatas:[],
            indicators:[],
            times:[],
            sheetId:null,
            startYear:'',
            endYear:'',
            metaLefts:'',
            metaTops:'',
            chartType:'bar',
            chartAreaSize:1,
            hotRef:null,
            tableCells:[],
            DescStatisticsVisible:false,
            GrowthRateVisible:false,
            logVisibile:false,
            difVisibile:false,
            lagVisibile:false,
            aggregationVisible:false,
            missValueVisibile:false,
            UserFunctionVisibile:false,
            bivarVisibile:false,
            partialVisibile:false,
            LineReturnVisibile:false,
            curvefitVisibile:false,
            leastSquaresVisibile:false,
            autocorreVisibile:false,
            HPFilterVisibile:false,
            expsmoothVisibile:false,
            arimaVisibile:false,
            UnitRootVisibile:false,
            GrangerCheckVisibile:false,
            VarModelVisibile:false,
            johansenVisibile:false,
            EngleGrangerVisibile:false,
            archVisibile:false,
            garchVisibile:false,
            userChartVisible:false,
            DescStatisticsResultVisible:false,
            descStatisticsData:{},
            userChartData:null,
            scatterSetVisible:false,
            bigChartVisible:false,
            showChartLegend:true,
            uploadTypeVisible:false,
            uploadMouldVisible:false,
            indicatorInfoVisible:false,
            infoParams:{},
            selectedRows:[],
            rows:[],
            cols:[],
            selectedUploadFreqId:null,
            userChartOption:null,            
            scatterData:null,
        }
    },
    computed: {
        reportId() {
            let id = this.$route.query.reportId;
            if(id) {
                return parseInt(id);
            }
            return null;
        },
        uploadId() {
            let id = this.$route.query.uploadId;
            if(id) {
                return parseInt(id);
            }
            return null;
        },
        freqId() {
            let freqId = this.$route.query.freqId;
            if(freqId) {
                return parseInt(freqId);
            }
            return null;
        },
        tableAreaStyle() {
            let obj = {};
            if(this.chartAreaSize===1) {
                obj.height='50%';
            } else if(this.chartAreaSize===2) {
                obj.height='calc(100% - 65px)';
            }
            return obj;
        },
        chartAreaStyle() {
            let obj = {};
            if(this.chartAreaSize===1) {
                obj = '50%';
            } else if(this.chartAreaSize===2) {
                obj.height='65px';
            }
            return obj;
        }
    },
    watch:{
        reportId(value) {
            if(!value) {
                return false;
            }
            this.selectedReport = '1-'+this.freqId+'-'+value;
            this.init(value);
        },
        uploadId(value) {
            if(!value) {
                return false;
            }
            this.selectedReport = '2-'+this.freqId+'-'+value;
            this.initMyDatas(value);
        },
        chartAreaSize() {
            setTimeout(()=>{
                this.hotRef.render();
                chartHandle.resize();
            },20);
        },
    },

    created() {
        if(this.reportId) {
            this.selectedReport = '1-'+this.freqId+'-'+this.reportId;
        } else if(this.uploadId) {
            this.selectedReport = '2-'+this.freqId+'-'+this.uploadId;
        }

        this.$http.getUserInfo().then((res)=> {
            if(!res) {return;}
            this.$store.commit('setUserInfo',res.userInfo);
        }).then(()=> {
            this.$http.cloud_get_all_report_cube().then((data)=> {
                if(!data||data.length==0) {					
                    return false;
                }
                this.allSeriesLength = data.length?data.length:0;
                this.series = this.dealReport(data);
                var hasCurrentReportId = false;				
                if(this.reportId) {
                    this.series.forEach((value)=> {
                        value.children.forEach((v)=> {
                            if(v.cube_ID==this.reportId) {
                                hasCurrentReportId = true;
                            }
                        });
                    });
                    if(hasCurrentReportId) {
                        this.init(this.reportId);
                    }					
                } else if(!this.reportId&&!this.uploadId) {
                    this.$router.push({path:'/cloud_index',query:{reportId:data[0].cube_ID,freqId:data[0].freq_ID}});
                    this.init(data[0].cube_ID);
                }
                return true;
            }).then(()=> {
                this.getMyData().then((res)=> {
                    var hasCurrentUploadId = false;
                    if(!res) {
                        return false;
                    }
                    if(!this.reportId&&this.uploadId) {
                        res.tree.forEach((value) => {
                            value.children.some((v) => {
                                if(v.cube_ID===this.uploadId) {
                                    hasCurrentUploadId = true;
                                    return true;
                                }
                            });
                        });
                        if(hasCurrentUploadId) {
                            this.initMyDatas(this.uploadId);
                        }
                    }
                });
            });
        });
    },


    methods:{
        selectAllSeries() {
            if(this.indicators.length===this.selectedIndicators.length&&this.selectedIndicators.length>0) {
                this.selectedIndicators = [];
            } else {
                this.indicators.forEach((item)=>{
                    let index = this.selectedIndicators.indexOf(item.dimCode);
                    if(index===-1) {
                        this.selectedIndicators.push(item.dimCode);
                    }
                })
                
            }
        },

        switchSider() {
            this.showSide = !this.showSide;
            if(this.showSide) {
                this.pageLayoutSiderStyle = pageLayoutSiderStyle;
            } else {
                this.pageLayoutSiderStyle = pageLayoutSiderHideStyle
            }
        },

        changeReport(e) {
            let arr = e.split('-');
            if(arr[0]==='1') {
                this.$router.push({path:'/cloud_index',query:{reportId:arr[2],freqId:arr[1]}});
            }else if(arr[0]==='2') {
                this.$router.push({path:'/cloud_index',query:{uploadId:arr[2],freqId:arr[1]}});
            }
        },

        dealReport(data) {
            var result = [{title:'年度时序数据',children:[]},{title:'季度时序数据',children:[]},{title:'月度时序数据',children:[]}];
            data.forEach((v)=> {
                if(v.freq_ID==1) {
                    result[0].children.push(v);
                } else if(v.freq_ID==3) {
                    result[1].children.push(v);
                } else if(v.freq_ID==4) {
                    result[2].children.push(v);
                }
            });
            return result;
        },
        
        init(reportId) {
            this.$http.cloud_get_dimension({reportId:reportId,cubeId:1596}).then((res)=> {
                if(res) {
                    if(!res.dataletBO) {
                        return false;
                    }
                    this.sheetId = res.dataletBO.sheetId;
                    if(!res.dataletBO.allDimensions) {
                        return false;
                    }
                    this.metaLefts = res.dataletBO.metaLefts[0];
                    this.metaTops = res.dataletBO.metaTops[0];
                    res.dataletBO.allDimensions.dimensionVOList.forEach(item=>{
                        if(item.dimensionId===this.metaLefts) {
                            this.indicators = item.objList;
                        }
                        if(item.dimensionId===this.metaTops) {
                            this.times = item.objList.reverse();
                        }                        
                    });
                    let timeCodes = [];
                    if(this.freqId===1) {
                        this.startYear = 0;
                        this.endYear = this.times.length-1;
                        timeCodes = this.times.map(item=>item.dimCode);
                    } else {
                        this.startYear = '0-0';
                        this.endYear = (this.times.length-1)+'-'+(this.times[this.times.length-1].children.length-1);
                        this.times.forEach((item,key)=>{
                            item.children.forEach((i,k)=>{
                                if(timeCodes.length<254) {
                                    timeCodes.push(i.dimCode);
                                } else if(timeCodes.length===254) {
                                    timeCodes.push(i.dimCode);
                                    this.endYear = key+'-'+k;
                                }                                
                            })
                        });
                    }
                     
                    let selectedIndicators = this.indicators.map(item=>item.dimCode);
                    this.selectedIndicators = [...selectedIndicators];
                    let dims = [{codeName:this.metaTops,codes:timeCodes},{codeName:this.metaLefts,codes:this.selectedIndicators}];
                    this.goSync(dims);
                }
            });
        },
        goSync(dims) {
            let params = {
                sheetId:this.sheetId,
                metaColumns:this.metaLefts,
                metaRows:this.metaTops,
            }
            if(this.$route.query.reportId) {
                params.reportId = this.$route.query.reportId;
            }
            if(this.$route.query.uploadId) {
                params.uploadId = this.$route.query.uploadId;
            }
            if(this.selectedIndicators.length===0) {
                messageTips('请选择序列',2);
                return false;
            }
            if(this.freqId===1) {
                if(this.startYear>=this.endYear) {
                    messageTips('请合理选择起止时间',2);
                    return false;
                }
            } else {
                let startKey = this.startYear.split('-').map(item=>parseInt(item));
                let endKey = this.endYear.split('-').map(item=>parseInt(item));
                if(startKey[0]>endKey[0]) {
                    messageTips('请合理选择起止时间',2);
                        return false;
                }else if(startKey[0]===endKey[0]) {
                    if(startKey[1]>=endKey[1]) {
                        messageTips('请合理选择起止时间',2);
                        return false;
                    }
                }
            }
            
            if(dims) {
                params.dims = JSON.stringify(dims);
            } else {
                let timeCodes = [];
                if(this.freqId===1) {
                    this.times.forEach((item,key)=>{
                        if(key>=this.startYear&&key<=this.endYear) {
                            timeCodes.push(item.dimCode);
                        }
                    });
                } else {
                    let startKey = this.startYear.split('-').map(item=>parseInt(item));
                    let endKey = this.endYear.split('-').map(item=>parseInt(item));
                    this.times.forEach((item,key)=>{
                        if(key===startKey[0]) {
                            item.children.forEach((i,k)=>{
                                if(k>=startKey[1]) {
                                    timeCodes.push(i.dimCode);
                                }
                            });
                        } else if(key>startKey[0]&&key<endKey[0]) {
                            item.children.forEach((i)=>{
                                timeCodes.push(i.dimCode);
                            });
                        } else if(key===endKey[0]) {
                            item.children.forEach((i,k)=>{
                                if(k<=endKey[1]) {
                                    timeCodes.push(i.dimCode);
                                }
                            });
                        }
                    })
                }
                
                let dim = [{codeName:this.metaTops,codes:timeCodes},{codeName:this.metaLefts,codes:this.selectedIndicators}];
                params.dims = JSON.stringify(dim);
            }
            this.spinning = true;
            this.$http.cloud_sync_data(params).then(res=>{
                this.spinning = false;
                if(!res) {return false}
                if(!res.dataletBO||!res.dataletBO.cellBOs) {
                    messageTips(res.message,1);
                    return false;
                }
                this.tableCells = res.dataletBO.cellBOs;
                let table = tableParse({
                    fixedColumnsLeft: 1,
                    fixedRowsTop:1,
                    values:res.dataletBO.cellBOs
                });
                this.renderTable(table);
                this.createChart('bar');
            });
        },
        initMyDatas(reportId) {
            this.$http.cloud_get_dimension({uploadId:reportId}).then((res)=> {
                if(res) {
                    if(!res.dataletBO) {
                        return false;
                    }
                    this.sheetId = res.dataletBO.sheetId;
                    if(!res.dataletBO.allDimensions) {
                        return false;
                    }
                    this.metaLefts = res.dataletBO.metaLefts[0];
                    this.metaTops = res.dataletBO.metaTops[0];
                    res.dataletBO.allDimensions.dimensionVOList.forEach(item=>{
                        if(item.dimensionId===this.metaLefts) {
                            this.indicators = item.objList;
                        }
                        if(item.dimensionId===this.metaTops) {
                            this.times = item.objList.reverse();
                        }                     
                    });
                    
                    let timeCodes = [];
                    if(this.freqId===1) {
                        this.startYear = 0;
                        this.endYear = this.times.length-1;
                        this.times.forEach(item=>{
                            timeCodes.push(item.dimCode)
                        });
                    } else {
                        this.startYear = '0-0';
                        this.endYear = (this.times.length-1)+'-'+(this.times[this.times.length-1].children.length-1);
                        this.times.forEach(item=>{
                            item.children.forEach(i=>{
                                timeCodes.push(i.dimCode)
                            });
                        });
                    }
                    let selectedIndicators = this.indicators.map(item=>item.dimCode);
                    this.selectedIndicators = [...selectedIndicators];
                    let dims = [{codeName:this.metaTops,codes:timeCodes},{codeName:this.metaLefts,codes:this.selectedIndicators}];
                    this.goSync(dims);
                }
            })
        },
        getMyData() {
            return this.$http.cloud_get_my_data().then((res)=> {
                if(!res||!res.myData) {
                    return false;
                }
                this.myDatasLength = res.myData.length;
                this.myDatas =  this.dealReport(res.myData);
                return {tree:this.myDatas,arr:res.myData};
            });
        },

        getIndicotor(data) {
            this.infoParams = {
                indicatorCode:data.obj,
                reportId:this.reportId
            }
            this.indicatorInfoVisible = Math.random();
        },
        afterTableSelectionEnd() {
            if(!this.chartType||this.chartType==='scatter') {
                return false;
            }
            let arr = this.hotRef.getSelected();
            let rows = [];
            let cols = [];
            arr.forEach(item=>{
                if(item[0]<item[2]) {
                    for(let i= item[0];i<=item[2];i++) {
                        if(rows.indexOf(i)===-1) {
                            rows.push(i);
                        }
                    }
                } else {
                    for(let i= item[2];i<=item[0];i++) {
                        if(rows.indexOf(i)===-1) {
                            rows.push(i);
                        }
                    }
                }
                if(item[1]<item[3]) {
                    for(let i= item[1];i<=item[3];i++) {                    
                        if(cols.indexOf(i)===-1) {
                            cols.push(i);
                        }
                    }
                } else {
                    for(let i= item[3];i<=item[1];i++) {                    
                        if(cols.indexOf(i)===-1) {
                            cols.push(i);
                        }
                    }
                }
            });

            rows.sort(sequence);
            cols.sort(sequence);
            if(cols[cols.length-1]<1||rows[rows.length-1]<1) {
                return false;
            }

            let params = {
                indexs:rows.join(','),yindexs:cols.join(','),
                sheetId:this.sheetId
            }
            this.$http.getChartData(params).then(res=>{
                if(res) {
                    if(chartHandle) {
                        chartHandle.dispose();
                    }
                    chartHandle = echarts.init(this.$refs.chart);
                    setCharts(chartHandle,this.chartType,res,this.showChartLegend,this.showChartDataLabel);
                }
            });
        },

        showModal(name){
            switch(name) {
                case 'descStatistics':
                    this.DescStatisticsVisible = Math.random();
                    break;
                case 'growthRate':
                    this.GrowthRateVisible = Math.random();
                    break;
                case 'log':
                    this.logVisibile = Math.random();
                    break;
                case 'diff':
                    this.difVisibile = Math.random();
                    break;
                case 'lag':
                    this.lagVisibile = Math.random();
                    break;
                case 'aggregation':
                    if(this.freqId===1) {
                        messageTips('时间聚合适用于季度数据和月度数据',2);
                        return false;
                    }
                    this.aggregationVisible = Math.random();
                    break;
                case 'missvalue':
                    this.missValueVisibile = Math.random();
                    break;
                case 'userFunction':
                    this.UserFunctionVisibile = Math.random();
                    break;
                case 'bivar':
                    this.bivarVisibile = Math.random();
                    break;
                case 'partial':
                    this.partialVisibile = Math.random();
                    break;
                case 'lineReturn':
                    this.LineReturnVisibile = Math.random();
                    break;
                case 'curvefit':
                    this.curvefitVisibile = Math.random();
                    break;
                case 'leastSquares':
                    this.leastSquaresVisibile = Math.random();
                    break;
                case 'autocorre':
                    this.autocorreVisibile = Math.random();
                    break;
                case 'hpFilter':
                    this.HPFilterVisibile = Math.random();
                    break;
                case 'expsmooth':
                    this.expsmoothVisibile = Math.random();
                    break;
                case 'arima':
                    this.arimaVisibile = Math.random();
                    break;
                case 'unitRoot':
                    this.UnitRootVisibile = Math.random();
                    break;
                case 'grangerCheck':
                    this.GrangerCheckVisibile = Math.random();
                    break;
                case 'varModel':
                    this.VarModelVisibile = Math.random();
                    break;
                case 'Johansen':
                    this.johansenVisibile = Math.random();
                    break;
                case 'EngleGranger':
                    this.EngleGrangerVisibile = Math.random();
                    break;
                case 'arch':
                    this.archVisibile = Math.random();
                    break;
                case 'garch':
                    this.garchVisibile = Math.random();
                    break;
                default:break;
            }
        },        
        createChart(type) {
            if(!this.hotRef) {
                return false;
            }
            if(chartHandle) {
                chartHandle.dispose();
            }
            chartHandle = echarts.init(this.$refs.chart);
            if(!type&&this.userChartOption) {
                chartHandle.setOption(this.userChartOption)
                return false;
            }
            this.chartType = type;

            if(this.hotRef.getSelected()) {
                this.afterTableSelectionEnd();
                return false;
            }
            
            let tableData = this.hotRef.getData();
            let indexs = [],yindexs = [];
            tableData.forEach((item,key)=>{
                if(key>0) {
                    indexs.push(key)
                }
            });
            tableData[0].forEach((item,key)=>{
                if(key>0) {
                    yindexs.push(key)
                }
            });
            let params = {
                indexs:indexs.join(','),yindexs:yindexs.join(','),
                sheetId:this.sheetId
            }
            this.$http.getChartData(params).then(res=>{
                if(res) {
                    setCharts(chartHandle,type,res,this.showChartLegend);
                }
            });
        },
        setUserChart(data) {
            if(chartHandle) {
                chartHandle.dispose();
            }
            chartHandle = echarts.init(this.$refs.chart);
            this.showChartLegend = data.showLegend;
            this.chartType = null;
            this.userChartOption = data.option;
            if(this.bigChartVisible) {
                bigChartHandle.setOption(data.option);
            } else {
                chartHandle.setOption(data.option);
            }
        },
        scatterFn() {
            this.scatterSetVisible = Math.random();
        },
        scatterSetCtrl(data) {
            if(chartHandle) {
                chartHandle.dispose();
            }
            chartHandle = echarts.init(this.$refs.chart);
            this.chartType = 'scatter';
            this.scatterData = data;
            if(this.bigChartVisible) {
                setCharts(bigChartHandle,'scatter',data,this.showChartLegend);
            } else {
                setCharts(chartHandle,'scatter',data,this.showChartLegend);
            }
        },
        userChartFn() {
            let tableData = this.hotRef.getData();            
            this.userChartData = {
                tableData,
                fixedLeft:1,
                fixedTop:1,
            }            
            this.userChartVisible = Math.random();
        },
        downloadImg(type) {
            let element;
            if(type===1) {
                element = this.$refs.chart;
            } else if(type===2) {
                element = this.$refs.bigChart;
            }
            html2canvas(element,{
                onrendered: function(canvas) {
                    document.body.appendChild(canvas);
                },
            }).then((canvas) => {
                let dataURL = canvas.toDataURL('image/png');
                savePicture(dataURL);
            });
        },
        setChartData() {
            let datas = this.getChartDatas();
            bigChartHandle = echarts.init(this.$refs.bigChart);
            if(this.chartType) {
                if(this.chartType==='scatter') {                
                    setBigChart(bigChartHandle,'scatter',this.scatterData,this.showChartLegend,this.showChartDataLabel);
                } else {                    
                    let xNames = this.cols.map(item=>item.key);
                    let yNames = this.series.map(item=>item.key);
                    setBigChart(bigChartHandle,this.chartType,{xNames,yNames,datas},this.showChartLegend,this.showChartDataLabel);
                }                
            } else {
                bigChartHandle.setOption(this.userChartOption);
            }
        },
        checkBigChart() {
            this.bigChartVisible = true;
            this.selectedRows = [];
            axios.all([this.getRows(),this.getCols()]).then(axios.spread((data,data2)=>{                
                this.selectedRows = data.map(item=>{
                    item.key = item.key.replace(/\|/,'-');
                    return item.index
                });
                this.rows = data;
                this.cols = data2;
                this.setChartData();                
            }));
        },
        getRows() {
            let params = {sheetId:this.sheetId}
            return this.$http.getVars(params)
        },
        getCols() {
            let params = {sheetId:this.sheetId}
            return this.$http.getColumnsMsg(params)
        },
        createBigChart(type) {
            if(bigChartHandle) {
                bigChartHandle.dispose();
            }
            this.chartType = type;
            bigChartHandle = echarts.init(this.$refs.bigChart);
            let xNames = this.cols.map(item=>item.key);
            let yNames = this.rows.map(item=>item.key);
            let datas = this.getChartDatas();
            
            let res = {
                xNames,
                yNames:[],
                datas:[]
            }
            let arr;
            if(type==='ring'||type==='pie') {
                if(typeof this.selectedRows==='object') {
                    this.selectedRows = this.selectedRows[0];
                }
                arr = [this.selectedRows-1];
            } else {
                if(typeof this.selectedRows!=='object') {
                    this.selectedRows = [this.selectedRows];
                }
                arr = this.selectedRows.map(item=>(item-1));
            }            
            arr.forEach(item=>{
                res.yNames.push(yNames[item]);
                res.datas.push(datas[item]);
            });
            setBigChart(bigChartHandle,type,res,this.showChartLegend);
        },
        clearResult() {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要清除结果吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    that.goSync();
                },
                onCancel() {},
            });
        },
        downloadTable() {
            this.$http.checkDownload({sheetId:this.sheetId}).then(res=>{
                if(res) {
                    if(res.canDownload) {                        
                        let params = {
                            sheetId:this.sheetId,
                            sid:getCookie('bd_sid'),
                            sign:res.sign
                        }
                        window.location.href = config.downloadTable+'?'+objToUrl(params);
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
        },
        changeSeries() {
            this.createBigChart(this.chartType);
        },
        showChartLegendFn() {
            if(!chartHandle) {
                return false;
            }
            this.showChartLegend = !this.showChartLegend;
            if(this.bigChartVisible) {
                if(this.chartType) {
                    showChartLegendFn(bigChartHandle,this.showChartLegend);
                } else {
                    this.userChartOption.legend.show = this.showChartLegend;
                    bigChartHandle.setOption(this.userChartOption);
                }
            } else {
                if(this.chartType) {
                    showChartLegendFn(chartHandle,this.showChartLegend);
                } else {
                    this.userChartOption.legend.show = this.showChartLegend;
                    chartHandle.setOption(this.userChartOption);
                }
            }
        },



        setDescResult(data) {
            this.DescStatisticsResultVisible = Math.random();
            this.descStatisticsData = data;
        },
        setGrowthRate(data) {
            this.calcResult(data.data);
        },
        setLogResult(data) {
            this.calcResult(data.data);
        },
        setDiffResult(data) {
            this.calcResult(data.data);
        },
        setLagResutl(data) {
            this.calcResult(data.data);
        },
        setMissvalueResult(data) {
            this.spinning = true;
            this.$http.cloud_set_missvalue(data.data).then(res=>{
                this.spinning = false;
                this.tableCells = res.values;
                let table = tableParse({
                    fixedColumnsLeft: 1,
                    fixedRowsTop:1,
                    values:res.values
                });1
                this.renderTable(table);
            });
        },
        setAggregationResult(data) {
            this.spinning = true;
            this.$http.cloud_set_aggregation(data.data).then(res=>{
                this.spinning = false;
                if(!res) {
                    return false;
                }
                this.tableCells = res.values;
                let table = tableParse({
                    fixedColumnsLeft: 1,
                    fixedRowsTop:1,
                    values:res.values
                });1
                this.renderTable(table);
            });
        },
        setUserFunction(data) {
            let params = {...data.data}
            this.spinning = true;
            this.$http.userDefinedFn(params).then(res=>{     
                this.spinning = false;        
                this.tableCells = res.cellBOs;
                let table = tableParse({
                    fixedColumnsLeft: 1,
                    fixedRowsTop:1,
                    values:res.cellBOs
                });                
                this.renderTable(table);
            })
        },

        calcResult(data) {
            this.spinning = true;
            this.$http.setGrowthRate(data).then(res=>{
                this.spinning = false; 
                if(res.success===false) {
                    messageTips(res.message,1);
                    return false;
                }         
                this.tableCells = res.dataletBO.cellBOs;
                let table = tableParse({
                    fixedColumnsLeft: 1,
                    fixedRowsTop:1,
                    values:res.dataletBO.cellBOs
                });
                this.renderTable(table);
            });
        },
        renderTable(table) {
            table.nosort = true;
            let settings = createSettings(table,this.getIndicotor);
            settings.afterSelectionEnd = this.afterTableSelectionEnd
            let handsontableDom = this.$refs.handsontable;
            if(this.hotRef&&this.hotRef.destroy) {
                this.hotRef.destroy();
            }
            this.hotRef = new Handsontable(handsontableDom, settings);                
            this.createChart(this.chartType);
            setTimeout(()=>{
                this.hotRef.render();
            },10);
        },

        refreshData() {
            this.spinning = true;
            this.$http.cloud_refresh({sheetId:this.sheetId}).then((res)=> {
                this.spinning = false;          
                this.tableCells = res.cellBOs;
                let table = tableParse({
                    fixedColumnsLeft: 1,
                    fixedRowsTop:1,
                    values:res.cellBOs
                });
                this.renderTable(table);
            });
        },

        getChartDatas() {
            let data = [];
            let tableData = this.hotRef.getData(); 
            tableData.forEach((item,key)=>{
                if(key>0) {
                    let arr = [];
                    item.forEach((i,k)=>{
                        if(k>0) {
                            arr.push(i);
                        }
                    });
                    data.push(arr);
                }
            });
            return data;
        },
        selectUploadType() {
            this.uploadTypeVisible = true;
        },
        selectUploadFreqId(type) {
            this.selectedUploadFreqId = type;
            this.uploadTypeVisible = false;
            this.uploadMouldVisible = Math.random();
        },
        uploadSuccess(data) {
            if(data) {
                this.getMyData().then((res)=>{
                    let isCovered = true;
                    this.myDatas.forEach((value) => {
                        value.children.some((v) => {
                            if(v.cube_ID===this.uploadId) {
                                isCovered = false;
                                return true;
                            }
                        });
                    });
                    if(isCovered) {
                        let node = res.arr[0];
                        this.$router.push({
                            path:'cloud_index',
                            query:{uploadId:node.cube_ID,freqId:node.freq_ID}
                        })
                    }
                });
            }
        },
        closeBigChart() {
            this.bigChartVisible=false;
            bigChartHandle.dispose();
            if(this.chartType) {
                if(this.chartType==='scatter') {
                    setCharts(bigChartHandle,'scatter',this.scatterData,this.showChartLegend,this.showChartDataLabel);
                } else {
                    this.createChart(this.chartType);
                }
                
            } else {
                chartHandle.setOption(this.userChartOption);
            }     
        }
    }
}
</script>
<style lang="scss">
@import '../../scss/_variables.scss';
.ant-spin-nested-loading {
    height:100%;
    .ant-spin-container {
        height:100%;
    }
}
.table-area {
    .ant-tabs-tabpane {
        background:#f0f3f6;
    }
    .ant-tabs-bar {
        margin-bottom: 0;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
        height:30px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        height:30px;
        line-height: 28px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
        height:30px;
        color:$primary-color;
    }
}
.data-table {
    .handsontable {    
        .iconfont.icon-info {
            color: rgba(251, 169, 81, 0.5);
            cursor: pointer;
        }
        .iconfont.icon-info:hover {
            color: $primary-color;
        } 
    }
    .handsontable table.htCore {
        border-collapse:collapse;border-spacing:1;
    }
    .handsontable th, .handsontable td,.handsontable .htDimmed {
        vertical-align:middle;
    }
    .ht_clone_top_left_corner thead tr th:nth-last-child(2) {
        border-right:none;
    }
    .handsontable th {
        background-color: #fff;
        color: #fff;
    }
    .handsontable tbody th.ht__active_highlight, .handsontable thead th.ht__active_highlight {
        color:#fff;
    }
    .handsontable th:first-child, .handsontable td:first-of-type, .handsontable thead tr th:nth-child(2), .handsontable .htNoFrame + th, .handsontable .htNoFrame + td {
        border:none;
    }
    .handsontable tr:first-child th, .handsontable tr:first-child td {
        border:none;
    }
    .handsontable th, .handsontable td {
        border:none;
        line-height:28px;
        padding:0 10px;
        height:28px;
    }
    .handsontable tr:nth-child(odd) td {
        background:#f0f3f6;
    }
    .handsontable tr:nth-child(even) {
        background:#fff;
    }
    .handsontable .htDimmed {
        background:none;
        color:#282828;
    }
    .handsontable tr:first-child td {
        border-top:1px solid #fff;
        border-bottom:1px solid #134f7a;
        background:#fff;
        /*border-right:1px solid #fff;*/
        white-space: nowrap;
    }

    .handsontable thead tr:first-child th, .handsontable thead tr:first-child td {
        background:#fff;
        border-top:none;
    }
    .handsontable.ht_clone_left .wtHider .wtSpreader .htCore tr:nth-child(odd) td {
        background:#f0f3f6;
    }
}
.ant-dropdown-menu {
    max-height: 300px;
    overflow: auto;
}
.drop-list-item {
    padding:5px 10px;
}
</style>
<style lang="scss" scoped>
@import '../../scss/cloud/index.scss';
</style>
<template>
    <a-modal v-model="filterVisible" title="ARIMA" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="deptIndex">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选范围：</div></a-col>
                <a-col flex="auto">
                    <a-row>
                        <a-col :span="8">
                            <a-select style="width:120px" v-model="startSection">
                                <a-select-option v-for="item in times" :key="item.timeCode" :value="item.timeCode">{{item.name}}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="4" style="text-align:center">——</a-col>
                        <a-col :span="8">
                            <a-select style="width:120px" v-model="endSection">
                                <a-select-option v-for="item in times" :key="item.timeCode" :value="item.timeCode">{{item.name}}</a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">预测到：</div></a-col>
                <a-col flex="auto">
                    <a-row>
                        <a-col :span="8">
                            <a-select style="width:120px" v-model="forecastSection">
                                <a-select-option v-for="item in forcastTimes" :key="item.timeCode" :value="item.timeCode">{{item.name}}</a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="16">
                            预测区间置信水平
                            <a-select style="width:70px" v-model="fmcl">
                                <a-select-option :value="0.90">90%</a-select-option>
                                <a-select-option :value="0.95">95%</a-select-option>
                                <a-select-option :value="0.99">99%</a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="16">
                        <div class="box">
                            <div class="name">模型阶段</div>
                            <div class="checkbox">
                                <a-checkbox-group>
                                    <div class="item">
                                        <div class="item-inner">
                                            自回归
                                        </div>
                                        p <a-input-number size="small" v-model="p" />
                                        sp <a-input-number size="small" v-model="sp" />
                                    </div>
                                    <div class="item">
                                        <div class="item-inner">
                                            差分
                                        </div>
                                        d <a-input-number size="small" v-model="d" />
                                        sd <a-input-number size="small" v-model="sd" />
                                    </div>
                                    <div class="item">
                                        <div class="item-inner">
                                            移动平均
                                        </div>
                                        q <a-input-number size="small" v-model="q" />
                                        sq <a-input-number size="small" v-model="sq" />
                                    </div>                           
                                </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">                
                        <div class="box">
                            <div class="name">因变量转换</div>
                            <div class="checkbox">
                                <a-radio-group v-model="deptTrans">
                                    <div class="item">
                                        <a-radio value="NONE">无</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio value="SQRT">平方根</a-radio>
                                    </div>    
                                    <div class="item">
                                        <a-radio value="LOG">自然对数</a-radio>
                                    </div>                          
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="h2">统计量设计</div>
            <div class="other">
                <a-checkbox-group v-model="tjl">
                    <div class="checkbox-item">
                        <div class="checkbox-item-inner">
                            <a-checkbox value="paraEst">参数估计</a-checkbox>
                        </div>
                    </div>
                    <div class="checkbox-item">
                        <div class="checkbox-item-inner">
                            <a-checkbox value="ra">残差自相关函数</a-checkbox>
                        </div>
                        最大之后阶数
                        <a-select style="width:60px" v-model="raMaxLag">
                            <a-select-option :value="5">5</a-select-option>
                            <a-select-option :value="10">10</a-select-option>
                            <a-select-option :value="15">15</a-select-option>
                            <a-select-option :value="20">20</a-select-option>
                        </a-select>
                    </div>
                    <div class="checkbox-item">
                        <div class="checkbox-item-inner">
                            <a-checkbox value="rpca"> 残差偏自相关函数</a-checkbox>
                        </div>
                        最大之后阶数
                        <a-select style="width:60px" v-model="rpcaMaxLag">
                            <a-select-option :value="5">5</a-select-option>
                            <a-select-option :value="10">10</a-select-option>
                            <a-select-option :value="15">15</a-select-option>
                            <a-select-option :value="20">20</a-select-option>
                        </a-select>
                    </div>
                </a-checkbox-group>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { getForcastTime } from '../../../until/data_tools';
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId','endYear','freqId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            times:[],
            fmcl:0.90,
            deptIndex:null,
            startSection:null,
            endSection:null,
            forecastSection:null,
            deptTrans:'NONE',
            raMaxLag:5,
            rpcaMaxLag:5,
            p:null,
            sp:null,
            d:null,
            sd:null,
            q:null,
            sq:null,
            tjl:[],
            spinning:false
        }
    },
    computed:{
        forcastTimes() {
            let arr = [];
            if(this.times.length>0) {
                arr = getForcastTime(this.freqId,this.times[this.times.length-1].timeCode); 
            }
            return [...arr];
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.list = [];
                this.times = [];
                this.fmcl = 0.90;
                this.deptIndex = null;
                this.startSection = null;
                this.endSection = null;
                this.forecastSection = null;
                this.deptTrans = 'NONE';
                this.raMaxLag =5;
                this.rpcaMaxLag = 5;
                this.p = null;
                this.sp = null;
                this.d = null;
                this.sd = null;
                this.q = null;
                this.sq = null;
                this.tjl = [];
                this.spinning = false;
                this.$http.cloud_get_variables({action:'expsmooth',sheetId:this.sheetId}).then(res=>{
                    if(res.indeptVars) {
                        this.list = res.indeptVars;
                        this.times = res.sampleSections;
                    }
                })
                this.filterVisible = true;
            }
        },
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(this.deptIndex===null) {
                messageTips('请选择变量',2);
                return false;
            }
            if(this.startSection===null||this.endSection===null) {
                messageTips('请选择起止时间',2);
                return false;
            }
            if(this.forecastSection===null) {
                messageTips('请选择预测时间',2);
                return false;
            }
            if(this.p===null||this.d===null||this.q===null) {
				messageTips('请输入参数（p,d,q）',2);
				return false;
			}
            let params = {
                fmcl:this.fmcl,
                deptIndex:this.deptIndex,
                startSection:this.startSection,
                endSection:this.endSection,
                forecastSection:this.forecastSection,
                deptTrans:this.deptTrans,                
                p:this.p,
                sp:this.sp,
                d:this.d,
                sd:this.sd,
                q:this.q,
                sq:this.sq,
                sheetId:this.sheetId
            }
            if(this.tjl.indexOf('paraEst')!==-1) {
				params.paraEst = 1;
			}
			if(this.tjl.indexOf('ra')!==-1) {
				params.ra = 1;
				params.raMaxLag = this.raMaxLag;
			}
			if(this.tjl.indexOf('rpca')!==-1) {
				params.rpca = 1;
				params.rpcaMaxLag = this.rpcaMaxLag;
            }
            this.spinning = true;
            this.$http.cloud_set_arima(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.success===false) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.$emit('refreshData',true);
                    this.filterVisible = false;
                    var result = {'analysis_arima':res}
                    setSessionItem('analysis_arima',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_arima'});
                    window.open(href, '_blank');
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }


    .other {
        margin-top: 20px;
    }
    .model-item {
        height:32px;
        line-height: 32px;
    }

    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:70px;
                }
            }
        }
    }
    .other {
        .checkbox-item {
            height:40px;
            line-height: 40px;
            .checkbox-item-inner {
                display: inline-block;
                width:150px;
            }
        }
    }

</style>
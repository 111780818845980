<template>
    <a-modal v-model="filterVisible" title="曲线估计" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">因变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="dept">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">自变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="indept">
                        <a-select-option :key="0" :value="0">T:时间</a-select-option>
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <div class="other">
                <div class="h2">选择模型：</div>
                <a-checkbox-group v-model="models">
                    <a-row>
                        <a-col :span="6" v-for="item in methods" :key="item.methodId">
                            <div class="model-item">
                                <a-checkbox :value="item.methodId">{{item.methodName}}</a-checkbox>
                            </div>
                        </a-col>
                        
                    </a-row>
                </a-checkbox-group>
            </div>

            <div class="other">
                预测区间置信水平
                <a-select style="width:60px;" size="small" v-model="fmcl">
                    <a-select-option :value="0.90">90%</a-select-option>
                    <a-select-option :value="0.95">95%</a-select-option>
                    <a-select-option :value="0.99">99%</a-select-option>
                </a-select>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            methods:[],
            dept:null,
            indept:null,
            models:[],
            fmcl:0.90,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.list = [];
                this.methods = [];
                this.models = [];
                this.dept = null;
                this.indept = null;
                this.fmcl = 0.90;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'curvefit',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                        this.methods = res.methods;
                        this.selectedMethod = res.methods[0].methodId;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(this.dept===null) {
                messageTips('请选择变量',2);
                return false;
            }
            if(this.indept===null) {
                messageTips('请选择变量',2);
                return false;
            }
            if(this.indept===this.dept) {
                messageTips('两个变量不要相同',2);
                return false;
            }
            let params = {
                dept:this.dept,
                indept:this.indept,
                models:this.models.join(','),
                sheetId:this.sheetId,
                fmcl:this.fmcl
            }
            this.spinning = true;
            this.$http.cloud_set_curvefit(params).then(res=>{ 
                this.spinning = false;     
                if(res) {
                    if(res.success===false) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.$emit('refreshData',true);
                    this.filterVisible = false;
                    var result = {'analysis_curvefit':res}
                    setSessionItem('analysis_curvefit',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_curvefit'});
                    window.open(href, '_blank');
                }          
                
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }


    .other {
        margin-top: 20px;
    }
    .model-item {
        height:32px;
        line-height: 32px;
    }

</style>
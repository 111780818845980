<template>
    <a-modal v-model="filterVisible" title="线性回归" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">因变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="dept">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <div class="variables">
                <div class="h2">自变量：</div>
                <div class="list">
                    <a-checkbox-group v-model="checkedList">
                        <div class="check-item" :key="0">
                            <a-checkbox :value="0">T:时间</a-checkbox>
                        </div>
                        <div class="check-item" v-for="item in list" :key="item.index">
                            <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
            </div>

            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="100px"><div class="label">选择方法：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="selectedMethod">
                        <a-select-option v-for="item in methods" :key="item.methodId" :value="item.methodId">{{item.methodName}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>


            <div class="h2" style="margin-top:20px">常规项：<a-radio-group v-model="selectedConsts"><a-radio :value="1">默认</a-radio><a-radio :value="0">无</a-radio></a-radio-group></div>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <div class="box">
                            <div class="name">保存项：</div>
                            <div class="checkbox">
                                <a-checkbox-group v-model="savingitems">
                                    <div class="item">
                                        <a-checkbox value="stdfitvalue">标准化预测值</a-checkbox>
                                    </div>
                                    <div class="item">
                                        <a-checkbox value="stdfiterr">标准化残差</a-checkbox>
                                    </div>
                                    <div class="item">
                                        <a-checkbox value="fci">预测区间置信水平
                                            <a-select size="small" style="width:60px" v-model="fmcl">
                                                <a-select-option :value="0.90">90%</a-select-option>
                                                <a-select-option :value="0.95">95%</a-select-option>
                                                <a-select-option :value="0.99">99%</a-select-option>
                                            </a-select>
                                        </a-checkbox>
                                    </div>                            
                                </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="12">                
                        <div class="box">
                            <div class="name">系统项：</div>
                            <div class="checkbox">
                                <a-checkbox-group v-model="coefitems">
                                    <div class="item">
                                        回归分析置信水平
                                        <a-select size="small" style="width:60px" v-model="rccl">                                        
                                            <a-select-option :value="0.90">90%</a-select-option>
                                            <a-select-option :value="0.95">95%</a-select-option>
                                            <a-select-option :value="0.99">99%</a-select-option>
                                        </a-select>
                                    </div>
                                    <div class="item">
                                        <a-checkbox value="cm">协方差矩阵</a-checkbox>
                                    </div>
                                    <div class="item">
                                        <a-checkbox value="cd">共线性诊断</a-checkbox>
                                    </div>                            
                                </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            methods:[],
            selectedMethod:null,
            selectedConsts:1,
            dept:null,
            savingitems:[],
            coefitems:[],
            fmcl:0.90,
            rccl:0.90,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.list = [];
                this.checkedList = [];
                this.methods = [];
                this.selectedMethod = null;
                this.dept = null;
                this.selectedConsts = 1;
                this.savingitems = [];
                this.coefitems = [];
                this.fmcl = 0.90;
                this.rccl = 0.90;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                        this.methods = res.methods;
                        this.selectedMethod = res.methods[0].methodId;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(this.checkedList.length===0) {
                messageTips('请选择变量',2);
                return false;
            }
            let params = {
                const:this.selectedConsts,
                dept:this.dept,
                indepts:this.checkedList.join(','),
                method:this.selectedMethod,
                savingitems:this.savingitems.join(','),
                coefitems:this.coefitems.join(','),
                fmcl:this.fmcl,
                rccl:this.rccl,
                sheetId:this.sheetId
            }
            this.spinning = true;
            this.$http.cloud_set_line_return(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.message&&res.success===false) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.$emit('refreshData',true);
                    this.filterVisible = false;
                    var result = {'analysis_lr':res}
                    setSessionItem('analysis_lr',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_lr'});
                    window.open(href, '_blank');
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
    .variables {
        margin-top: 20px;
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }

    .other {
        margin-top: 20px;
    }
    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
        }
    }
</style>
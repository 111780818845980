<template>
    <a-modal v-model="filterVisible" title="二阶段最小二乘" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="120px"><div class="label">被解释变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="dept">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <div class="variables">
                <div class="h2">解释变量</div>
                <div class="list">
                    <a-checkbox-group v-model="checkedList1">
                        <div class="check-item" v-for="item in list" :key="item.index">
                            <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
            </div>

            <div class="variables">
                <div class="h2">工具变量：</div>
                <div class="list">
                    <a-checkbox-group v-model="checkedList2">
                        <div class="check-item" v-for="item in list" :key="item.index">
                            <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
            </div>

            <div class="other">
                <a-checkbox :checked="checkedConst" @change="e=>{checkedConst=e.target.checked}">常数项</a-checkbox>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList1:[],
            checkedList2:[],
            dept:null,
            checkedConst:false,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.list = [];
                this.dept = null;
                this.checkedConst = false;
                this.checkedList1 = [];
                this.checkedList2 = [];
                this.spinning = false;
                this.$http.cloud_get_variables({action:'leastSquares',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(!this.dept) {
                messageTips('请选择被解释变量',2);
                return false;
            }
            if(this.checkedList1.length===0) {
                messageTips('请选择解释变量',2);
                return false;
            }
            if(this.checkedList2.length===0) {
                messageTips('请选择工具变量',2);
                return false;
            }
            let params = {
                dept:this.dept,
                expIndexs:this.checkedList1.join(','),
                ctrlIndexs:this.checkedList2.join(','),
                method:'gryoy',
                sheetId:this.sheetId,
                const:this.checkedConst?1:0
            }
            this.spinning = true;
            this.$http.cloud_set_leastSquares(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.success===false) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.$emit('refreshData',true);
                    this.filterVisible = false;
                    var result = {'analysis_leastSquares':res}
                    setSessionItem('analysis_leastSquares',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_leastSquares'});
                    window.open(href, '_blank');
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
</style>
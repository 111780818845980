<template>
    <a-modal v-model="filterVisible" title="指标信息" :footer="null" :centered="true" @cancel="cancelHandle">
        <div v-if="infomation!==null&&infomation!==undefined">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px">指标名称</a-col>
                <a-col flex="auto">{{infomation.indicator_NAME}}</a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px">数据库</a-col>
                <a-col flex="auto">{{infomation.dim_CUBE}}</a-col>
            </a-row> 
            <a-row type="flex" :gutter="[16,16]" v-if="infomation.dim_SOURCE">
                <a-col flex="80px">来源</a-col>
                <a-col flex="auto">{{infomation.dim_SOURCE}}</a-col>
            </a-row>           
            <a-row type="flex" :gutter="[16,16]" v-if="infomation.dim_ORG">
                <a-col flex="80px">机构</a-col>
                <a-col flex="auto">{{infomation.dim_ORG}}</a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]" v-if="infomation.dim_COMMENT">
                <a-col flex="80px">说明</a-col>
                <a-col flex="auto">{{infomation.dim_COMMENT}}</a-col>
            </a-row>
        </div>
        <div v-else>
            <a-empty description="无指标信息" />
        </div>
    </a-modal>
</template>

<script>
export default {
    name:'IndicatorInfo',
    props:['visible','params'],
    data() {
        return {
            filterVisible:false,
            infomation:null
        }
    },
    watch:{
        visible(value) {
            if(value) {                
                this.filterVisible = true;
                this.infomation = null;
                let params = {...this.params};
                params.dimType = 'INDICATOR';
                this.$http.cloud_get_indicator_info(params).then(res=>{
                    if(res&&res.list) {
                        this.infomation = res.list[0];
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancelHandle() {
            this.filterVisible = false;
        }
    }
}
</script>
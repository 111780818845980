<template>
    <a-modal v-model="filterVisible" title="双变量" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <div class="variables">
                <div class="h2">选择变量：<a-checkbox @click="selectAll" :indeterminate="checkedList.length>0&&checkedList.length<list.length" :checked="checkedList.length===list.length&&list.length>0">全选</a-checkbox></div>
                <div class="list">
                    <a-checkbox-group v-model="checkedList">
                        <div class="check-item" v-for="item in list" :key="item.index">
                            <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
            </div>
            <div class="variables">
                <a-row :gutter="[16,16]">
                    <a-col :span="24">
                        <div class="box">
                            <div class="name">选择系数</div>
                            <div class="checkbox">
                            <a-checkbox-group v-model="correlations" style="width:100%">
                                <a-row>
                                        <a-col :span="6">
                                            <a-checkbox value="pearson">Pearson</a-checkbox>
                                        </a-col>
                                        <a-col :span="8">
                                            <a-checkbox value="kendall">Kendall's tau-b</a-checkbox>
                                        </a-col>
                                        <a-col :span="6">
                                            <a-checkbox value="spearman">Spearman</a-checkbox>
                                        </a-col>
                                    </a-row>
                            </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>                
                </a-row>
            </div>

            <div class="other">
                <div class="h2">显著性检验：
                    <a-radio-group v-model="sign">
                        <a-radio :value="2" style="font-weight:normal">双侧</a-radio>
                        <a-radio :value="1" style="font-weight:normal">单侧</a-radio>
                    </a-radio-group>
                </div>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            sign:2,
            correlations:[],
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.list = [];
                this.checkedList = [];
                this.correlations = [];
                this.spinning = false;
                this.sign = 2;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },
        selectAll() {
            if(this.checkedList.length === this.list.length) {
                this.checkedList = [];
            } else {
                this.list.forEach(item=>{
                    let index = this.checkedList.indexOf(item.index);
                    if(index===-1) {
                        this.checkedList.push(item.index);
                    }
                });
            }            
        },
        goApply() {
            if(this.checkedList.length===0) {
                messageTips('请选择变量',2);
                return false;
            }
            if(this.correlations.length===0) {
                messageTips('请选择系数',2);
                return false;
            }
            let params = {
                action:'bivar',
                variables:this.checkedList.join(','),
                correlations:this.correlations.join(','),
                fmcl:0.95,
                sign:this.sign,
                sheetId:this.sheetId,
            }
            this.spinning = true;
            this.$http.cloud_set_correlation(params).then(res=>{
                this.spinning = false;
                if(res.success===false) {
                    messageTips(res.message,1);
                    return false;
                }
                let result = {'analysis_bivar':res}
                setSessionItem('analysis_bivar',JSON.stringify(result));
                this.filterVisible = false;
                let {href} = this.$router.resolve({path: '/analysis_bivar'});
                window.open(href, '_blank');
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
    .checkbox {
        padding-top: 10px;
    }

    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }
</style>
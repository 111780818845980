<template>
	<a-drawer
		title="描述性统计"
		placement="bottom"
		:closable="false"
		:visible="filterVisible"
		@close="onClose"
		height="50%"
	>
		<table style="width:800px;margin:0 auto" v-if="data">
			<tr>
				<th>统计名</th>
				<th>数值</th>
				<th>统计名</th>
				<th>数值</th>
			</tr>
			<tr>
				<td>取样描述</td>
				<td></td>
				<td>离散趋势</td>
				<td></td>
			</tr>
			<tr>
				<td>观察数目</td>
				<td>{{data.nonEmptyStats || '&nbsp;'}}</td>
				<td>标准差</td>
				<td>{{data.standardDeviation || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>缺失值数目</td>
				<td>{{data.emptyNum || '&nbsp;'}}</td>
				<td>方差</td>
				<td>{{data.variance || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>集中趋势</td>
				<td></td>
				<td>最大值</td>
				<td>{{data.max || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>均值</td>
				<td>{{data.mean || '&nbsp;'}}</td>
				<td>最小值</td>
				<td>{{data.min || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>中位数</td>
				<td>{{data.median || '&nbsp;'}}</td>
				<td>极差</td>
				<td>{{data.range || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>众数</td>
				<td>{{data.modes || '&nbsp;'}}</td>
				<td>半极差</td>
				<td>{{data.interquartileRange || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>合计</td>
				<td>{{data.total || '&nbsp;'}}</td>
				<td>均值标准误差</td>
				<td>{{data.seMean || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>百分位值</td>
				<td></td>
				<td>变异系数</td>
				<td>{{data.coefficientOfVariation || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>10%</td>
				<td>{{data.upperDecile || '&nbsp;'}}</td>
				<td>峰度</td>
				<td>{{data.skewness || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>25%(Q1)</td>
				<td>{{data.upperQuartile || '&nbsp;'}}</td>
				<td>偏度</td>
				<td>{{data.kurtosis || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>75%(Q3)</td>
				<td>{{data.lowerQuartile || '&nbsp;'}}</td>
				<td>JB量计算</td>
				<td>{{data.jarqueBera || '&nbsp;'}}</td>
			</tr>
			<tr>
				<td>90%</td>
				<td>{{data.lowerDecile || '&nbsp;'}}</td>
				<td></td>
				<td></td>
			</tr>
		</table>
	</a-drawer>
</template>
<script>
export default {
    props:['data','visible'],
    data() {
        return {
            filterVisible:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
            }
        }
    },
    methods:{
        onClose() {
            this.filterVisible = false;
        },
    }
}
</script>

<style lang="scss" scoped>
    table {
        tr:nth-child(odd) {
            background:#f0f3f6;
		}
		td,th {
			padding:3px 5px;
		}
    }
</style>
<template>
    <a-modal v-model="filterVisible" title="时间聚合" :footer="null" :centered="true">
        <a-row type="flex" :gutter="[16,16]">
            <a-col flex="80px"><div class="label">选择序列</div></a-col>
            <a-col flex="auto">
                <a-select style="width:100%" v-model="dept">
                    <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                </a-select>
            </a-col>
        </a-row>

        <div class="other">
            <a-row :gutter="[16,16]">
                <a-col :span="24">
                    <div class="box">
                        <div class="name">聚合方法</div>
                        <div class="checkbox">
                           <a-radio-group style="width:100%" v-model="selectedWay">
                               <a-row>
                                <a-col :span="6" v-for="item in ways" :key="item.value">
                                    <a-radio :value="item.value">{{item.name}}</a-radio>
                                </a-col>
                            </a-row>
                           </a-radio-group>
                        </div>
                    </div>
                </a-col>
                
            </a-row>
        </div>
        <div class="h2">频度：</div>
        <div class="freq">
            <a-radio-group style="width:100%" v-model="freq">
                <a-row>
                    <a-col :span="5">
                        <a-radio :value="1">年度</a-radio>
                    </a-col>
                    <a-col :span="5">
                        <a-radio :value="2">半年度</a-radio>
                    </a-col>
                    <a-col :span="5">
                        <a-radio :value="3">季度</a-radio>
                    </a-col>
                </a-row>
            </a-radio-group>
        </div>

        <div class="btns">
            <a-button type="primary" ghost @click="cancel">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            dept:null,
            selectedWay:'',
            freq:null,
            ways:[
                {name:'最大值聚合',value:'MAX_AGGRE'},
                {name:'最小值聚合',value:'MIN_AGGRE'},
                {name:'首元素聚合',value:'FIR_AGGRE'},
                {name:'末元素聚合',value:'LAST_AGGRE'},
                {name:'平均值聚合',value:'MEAN_AGGRE'},
                {name:'标准差',value:'STD_AGGRE'},
                {name:'求和',value:'SUM_AGGRE'}
            ]
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.freq = 1;
                this.selectedWay = this.ways[0];
                this.dept = null;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },


        goApply() {
            if(!this.dept) {
                messageTips('请选择变量',2);
                return false;
            }
            let params = {
                action:'gr',
                indexs:this.dept,
                method:this.selectedWay,
                sheetId:this.sheetId,
                freq:this.freq,
            } 
            this.$emit('setAggregationResult',{data:params});
            this.filterVisible = false;
        },
    }
}
</script>

<style lang="scss" scoped>

.h2 {
    font-weight: bold;
    .ant-checkbox-wrapper {
        font-weight:normal;
    }
}
.label {
    font-weight: bold;
    height:32px;
    line-height: 32px;
}

.box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }
</style>
export function setCube(arr) {
    let result = getOneLevels(arr,0);
    result.forEach(item=>{
        item.children = getOneLevels(arr,item.cubeId);
    });
    return result;
}

function getOneLevels(arr,pid,flag) {
    let result = [];
    if(flag) {
        arr.forEach(item=>{
            if(item.cubeId===pid) {
                result.push(item)
            }
        });
    } else {
        arr.forEach(item=>{
            if(item.pid===pid) {
                result.push(item)
            }
        });
    }
    
    return result;
}

export function getForcastTime(freqId,lastCode) {
    let arr = [];
    var year = parseInt(lastCode.substr(0,4));
    var month = lastCode.substr(4,2);
    switch(freqId) {
        case 1:
            for(let i=0;i<4;i++) {
                year+=1;
                let obj = {timeCode:year+'010101',name:year+'年'};
                arr.push(obj);
            }
        break;
        case 3:
            month = parseInt(month);
            for(let i=0;i<12;i++) {
                let obj;
                if(month<10) {
                    month+=3;
                    if(month<10) {
                        obj = {timeCode:year+'0'+month+'0103',name:year+'年'+Math.ceil(month/3)+'季度'}
                    } else {
                        obj = {timeCode:year.toString()+month+'0103',name:year+'年'+Math.ceil(month/3)+'季度'}
                    }
                } else {
                    year+=1;
                    month = 1;
                    obj = {timeCode:year+'0'+month+'0103',name:year+'年'+Math.ceil(month/3)+'季度'}
                }
                arr.push(obj);
            }
        break;
        case 4:
            month = parseInt(month);
            for(let i=0;i<36;i++) {
                let obj;
                if(month<12) {
                    month+=1;
                    if(month<10) {
                        obj = {timeCode:year+'0'+month+'0104',name:year+'年'+month+'月'}
                    } else {
                        obj = {timeCode:year.toString()+month+'0104',name:year+'年'+month+'月'}
                    }
                } else {
                    year+=1;
                    month = 1;
                    obj = {timeCode:year+'0'+month+'0104',name:year+'年'+month+'月'}
                }
                arr.push(obj);
            }
        break;
    }
    return arr;
}
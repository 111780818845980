<template>
    <a-modal v-model="filterVisible" title="GARCH模型" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选变量</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="index">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            
            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <div class="box">
                            <div class="name">均值方程</div>
                            <div class="checkbox">
                                <div class="item">
                                    <div class="item-inner">AR-lag</div>
                                    <a-input-number size="small" v-model="arLag" />
                                </div>
                                <div class="item">
                                    <div class="item-inner">Ma-lag</div>
                                    <a-input-number size="small" v-model="maLag" />
                                </div>
                                <div class="item"></div>
                            </div>
                        </div>
                    </a-col>

                    <a-col :span="12">
                        <div class="box">
                            <div class="name">方差模型</div>
                            <div class="checkbox">
                                <div class="item">
                                    <div class="item-inner">模型选择</div>
                                    <a-select size="small" style="width:120px" :dropdownMatchSelectWidth="false" v-model="vmodel">
                                        <a-select-option v-for="item in fcModelList" :key="item.value">{{item.name}}</a-select-option>
                                    </a-select>
                                </div>
                                <div class="item">
                                    <div class="item-inner">ARCH-lag</div>
                                    <a-input-number size="small" v-model="archLag" />
                                </div>
                                <div class="item">
                                    <div class="item-inner">GARCH-lag</div>
                                    <a-input-number size="small" v-model="garchLag" :disabled="vmodel===1" />
                                </div>
                            </div>
                        </div>
                    </a-col>      
                </a-row>
            </div>

            <div class="blank" style="height:20px;"></div>

            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">分布类型</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:200px" v-model="dmodel">
                        <a-select-option v-for="item in types" :key="item.index" :value="item.value">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">预测时间</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:200px" v-model="ahead">
                        <a-select-option v-for="(item,key) in forcastTimes" :key="item.timeCode" :value="key+1">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
import { getForcastTime } from '../../../until/data_tools';
export default {
    props:['visible','sheetId','times','endYear','freqId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            fcModelList:[
                {value:1,name:'ARCH模型'},
                {value:8,name:'GARCH模型'},
                {value:2,name:'求和GARCH模型'},
                {value:6,name:'GARCH均值模型'},
                {value:3,name:'指数GARCH模型'},
                {value:4,name:'门限GARCH模型'},
            ],
            types:[
                {value:1,name:'正态分布'},
                {value:2,name:'偏态分布'},
                {value:3,name:'t分布'},
                {value:4,name:'偏态t分布'},
                {value:5,name:'广义误差分布'},
                {value:6,name:'偏态广义误差分布'}
            ],
            index:null,
            arLag:1,
            maLag:1,
            archLag:1,
            garchLag:1,
            ahead:null,
            vmodel:1,
            dmodel:1,
            spinning:false
        }
    },
    computed:{
        forcastTimes() {            
            if(this.freqId&&this.endYear&&this.times.length>0) {
                let arr = [];
                if(this.freqId===1) {
                    if(this.times[this.endYear]) {
                        arr = getForcastTime(this.freqId,this.times[this.endYear].dimCode);
                    }
                } else if(this.freqId===3||this.freqId===4) {
                    if(typeof this.endYear==='string') {
                        let endKey = this.endYear.split('-').map(item=>parseInt(item));
                        if(this.times[endKey[0]]&&this.times[endKey[0]].children[endKey[1]]) {
                            arr = getForcastTime(this.freqId,this.times[endKey[0]].children[endKey[1]].dimCode);
                        }
                    }
                }
                return arr;
            }
            return [];
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.index = null;
                this.arLag = 1;
                this.maLag = 1;
                this.archLag = 1;
                this.garchLag = 1;
                this.ahead = null;
                this.vmodel = 1;
                this.dmodel = 1;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },


        goApply() {
            if(!this.index) {
                messageTips('请选择变量',2);
                return false;
            }
            if(!this.ahead) {
                messageTips('请选择预测年份',2);
                return false;
            }
            let params = {
                index:this.index,
                dmodel:this.dmodel,
                vmodel:this.vmodel,
                ahead:this.ahead,
                arLag:this.arLag,
                maLag:this.maLag,
                archLag:this.archLag,
                garchLag:this.garchLag,
                sheetId:this.sheetId
            }
            this.spinning = true;
            this.$http.cloud_set_garch(params).then(res=>{
                this.spinning = false;
                if(res.success===false) {
                    messageTips(res.message,1);
                    return false;
                }
                if(res.isRefresh) {
                    this.$emit('refreshData',true);
                }
                this.filterVisible = false;
                let result = {'analysis_garch':res}
                setSessionItem('analysis_garch',JSON.stringify(result));
                let {href} = this.$router.resolve({path: '/analysis_garch'});
                window.open(href, '_blank');
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.label {
    font-weight: bold;
    height:32px;
    line-height: 32px;
}

.box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }
</style>
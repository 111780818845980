<template>
    <a-modal v-model="filterVisible" title="Engle-Granger协整分析" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">因变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="reasonIndex">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">自变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="selfIndex">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="24">
                        <div class="box">
                            <div class="name">选择类型</div>
                            <div class="checkbox">
                                <a-row>
                                    <a-col :span="8">
                                        检验类型
                                        <a-radio-group v-model="method">
                                            <div class="item">
                                                <a-radio :value="1">ADF检测</a-radio>
                                            </div>
                                            <div class="item">
                                                <a-radio :value="2">pp检测</a-radio>
                                            </div>
                                            <div class="item">
                                                <a-radio :value="3">kpss检测</a-radio>
                                            </div>
                                        </a-radio-group>
                                    </a-col>

                                    <a-col :span="9">
                                        检验对象
                                        <a-radio-group v-model="objType">
                                            <div class="item">
                                                <a-radio :value="1">水平序列</a-radio>
                                            </div>
                                            <div class="item">
                                                <a-radio :value="2">一阶差分序列</a-radio>
                                            </div>
                                            <div class="item">
                                                <a-radio :value="3">二阶差分序列</a-radio>
                                            </div>
                                        </a-radio-group>
                                    </a-col>

                                    <a-col :span="6">
                                        检验类型
                                        <a-radio-group v-model="type">
                                            <div class="item">
                                                <a-radio :value="1">无</a-radio>
                                            </div>
                                            <div class="item">
                                                <a-radio :value="2">截距项</a-radio>
                                            </div>
                                            <div class="item">
                                                <a-radio :value="3">趋势项</a-radio>
                                            </div>
                                        </a-radio-group>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                    </a-col>            
                </a-row>
            </div>

            <div class="other">
                <a-row>
                    <a-col :span="3"><div class="label">滞后阶数</div></a-col>
                    <a-col :span="6">
                        <a-input-number v-model="number" :min="0" />
                    </a-col>
                </a-row>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            number:5,
            reasonIndex:null,
            selfIndex:null,
            method:1,
            objType:1,
            type:1,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.reasonIndex = null;
                this.selfIndex = null;
                this.method = 1;
                this.objType = 1;
                this.type = 1;
                this.number = 5;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },


        goApply() {
            if(this.reasonIndex===null) {
                messageTips('请选择因变量',2);
                return false;
            }
            if(this.selfIndex===null) {
                messageTips('请选择自变量',2);
                return false;
            }
            let params = {
                reasonIndex:this.reasonIndex,
                selfIndex:this.selfIndex,
                lags:this.number,
                method:this.method,
                objType:this.objType,
                type:this.type,
                sheetId:this.sheetId
            }
            this.spinning = true;
            this.$http.cloud_set_eg(params).then(res=>{
                this.spinning = false;
                if(res.success===false) {
                    messageTips(res.message,1);
                    return false;
                }
                if(res.isRefresh) {
                    this.$emit('refreshData',true);
                }
                this.filterVisible = false;
                let result = {'analysis_engleGranger':res}
                setSessionItem('analysis_engleGranger',JSON.stringify(result));
                let {href} = this.$router.resolve({path: '/analysis_engleGranger'});
                window.open(href, '_blank');
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
    .other {
        padding-top: 20px;
    }

    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }
</style>
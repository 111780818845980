<template>
    <a-modal v-model="filterVisible" title="自相关分析" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="variableIndex">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>        

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <div class="box">
                            <div class="name">模型阶段：</div>
                            <div class="checkbox">
                                <a-checkbox-group v-model="output">
                                    <div class="item">
                                        <a-checkbox value="ac">自相关</a-checkbox>
                                    </div>
                                    <div class="item">
                                        <a-checkbox value="pac">偏自相关</a-checkbox>
                                    </div>
                                    <div class="item"></div>                            
                                </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="12">                
                        <div class="box">
                            <div class="name">序列处理：</div>
                            <div class="checkbox">
                                <a-checkbox-group v-model="preDeal">
                                    <div class="item">
                                        <a-checkbox value="logTrans">自然对数转换</a-checkbox>
                                    </div>
                                    <div class="item">
                                        <a-checkbox value="hasdiff">差分
                                            <a-input-number size="small" v-model="diff" :min="0" />
                                        </a-checkbox>
                                    </div>    
                                    <div class="item">
                                        <a-checkbox value="hasseasonal">季节性差分
                                            <a-input-number size="small" v-model="seasonal" :min="0" />
                                        </a-checkbox>
                                    </div>                          
                                </a-checkbox-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="other">
                最大滞后数
                <a-select style="width:80px" v-model="maxlag">
                    <a-select-option :value="5">5</a-select-option>
                    <a-select-option :value="10">10</a-select-option>
                    <a-select-option :value="20">20</a-select-option>
                    <a-select-option :value="30">30</a-select-option>
                </a-select>
            </div>
            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            maxlag:5,
            output:[],
            preDeal:[],
            variableIndex:null,
            diff:null,
            seasonal:null,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.variableIndex = null;
                this.output = [];
                this.preDeal = [];
                this.list = [];
                this.maxlag = 5;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(!this.variableIndex) {
                messageTips('请选择变量',2);
                return false;
            }
            let params = {
                variableIndex:this.variableIndex,
                output:this.output.join(','),
                maxlag:this.maxlag,
                sheetId:this.sheetId
            }
            if(this.preDeal.indexOf('hasdiff')!==-1&&this.diff) {
                params.hasdiff = 1;
                params.diff = this.diff;
            }
            if(this.preDeal.indexOf('hasseasonal')!==-1&&this.seasonal) {
                params.hasseasonal = 1;
                params.seasonal= this.seasonal;
            }
            if(this.preDeal.indexOf('logTrans')!==-1) {
                params.logTrans = 1;
            }
            this.spinning = true;
            this.$http.cloud_set_autocorre(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.message) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.filterVisible = false;
                    var result = {'analysis_autocorre':res}
                    setSessionItem('analysis_autocorre',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_autocorre'});
                    window.open(href, '_blank');
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }

    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
            }
        }
    }
</style>
<template>
    <a-modal v-model="filterVisible" title="对数" :footer="null" :centered="true">
        <div class="variables">
            <div class="h2">选择变量：<a-checkbox @click="selectAll" :indeterminate="checkedList.length>0&&checkedList.length<list.length" :checked="checkedList.length===list.length&&list.length>0">全选</a-checkbox></div>
            <div class="list">
                <a-checkbox-group v-model="checkedList">
                    <div class="check-item" v-for="item in list" :key="item.index">
                        <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                    </div>
                </a-checkbox-group>
            </div>
        </div>

        <div class="ways">
            <div class="h2">方法：</div>
            <a-radio-group v-model="selectedType">
                <a-row>
                    <a-col :span="7">
                        <a-radio :value="'ln_'">自然对数
                        </a-radio>
                    </a-col>
                    <a-col :span="9">
                        <a-radio :value="'log_2_'">以2为底的对数

                        </a-radio>
                    </a-col>
                    <a-col :span="8">
                        <a-radio :value="'log_10_'">以10为底的对数
                        </a-radio>
                    </a-col>
                </a-row>
            </a-radio-group>
        </div>
        
        <div class="btns">
            <a-button type="primary" ghost @click="cancel">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            selectedType:'ln_',
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.checkedList = [];
                this.selectedType = 'ln_';
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;

            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },
        selectAll() {
            if(this.checkedList.length === this.list.length) {
                this.checkedList = [];
            } else {
                this.list.forEach(item=>{
                    let index = this.checkedList.indexOf(item.index);
                    if(index===-1) {
                        this.checkedList.push(item.index);
                    }
                });
            }            
        },
        goApply() {
            if(this.checkedList.length===0) {
                messageTips('请选择变量',2);
                return false;
            }
            let params = {
                action:'logarithm',
                indexs:this.checkedList.join(','),
                method:this.selectedType,
                sheetId:this.sheetId,
            }
            this.$emit('setLogResult',{data:params});
            this.filterVisible = false;
        },
        
        
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
</style>
<template>
    <a-modal v-model="uploadMouldVisible" wrapClassName="moudle-win" :width="1050" :title="freqId===1?'年度数据模板':freqId===3?'季度数据模板':'月度数据模板'" :footer="null" :centered="true">
        
        <div class="moudle-name">
            <span class="iconfont icon-Microsoft-Excel"></span>
            <span v-if="freqId===1">年度数据模板.xls</span>
            <span v-if="freqId===3">季度数据模板.xls</span>
            <span v-if="freqId===4">月度数据模板.xls</span>
        </div>

        <div class="excel">
				<table cellpadding="0" cellspacing="0">
					<thead>
						<tr>
							<th><i class="triangle"></i></th>
							<th v-for="item in tableHead" :key="item">{{item}}</th>
						</tr>
					</thead>
					<tbody>
                        <tr class="times" v-if="freqId===1">
							<th>1</th>
							<td v-for="(i,k) in times.year" :key="k" :class="{'i-name':k==0}">{{i}}</td>
						</tr>
                        <tr class="times" v-if="freqId===3">
							<th>1</th>
							<td v-for="(i,k) in times.quarter" :key="k" :class="{'i-name':k==0}">{{i}}</td>
						</tr>
                        <tr class="times" v-if="freqId===4">
							<th>1</th>
							<td v-for="(i,k) in times.month" :key="k" :class="{'i-name':k==0}">{{i}}</td>
						</tr>
						<tr v-for="(item,key) in tableData" :key="key">
							<th>{{key+2}}</th>
							<td v-for="(i,k) in item" :key="k" :class="{'i-name':k==0}">{{i}}</td>
						</tr>
					</tbody>				
				</table>
			</div>

        <div class="tips">
            您想上传的文件是否符合以上标准？如果符合请点击下面“<span class="green">上传</span>”按钮；<br/>
            如果不符合，还请您按此格式进行编辑之后再上传<br/>
            <span class="red">*文件大小不要超过1M</span>
        </div>
        <div class="btns">
            <a-upload
                :multiple="true"
                :action="uploadUrl+'&freqId='+freqId"
                :headers="headers"
                @change="handleChange"
                :before-upload="beforeUpload"
                name="myfile"
                :showUploadList="false"
                accept=".xls,.xlsx"
            ><a-button type="primary"><a-icon type="upload" />上传</a-button></a-upload>
            
        </div>
    </a-modal>
</template>

<script>
import config from '../../../until/configs';
import { getCookie, messageTips } from '../../../until/tools';
export default {
    props:['visible','freqId'],
    data() {
        return {
            uploadMouldVisible:false,
            uploadUrl:config.cloudUploadData+'?sid='+getCookie('bd_sid'),
            headers: {
                authorization: 'authorization-text',
            },
            tableHead:['A','B','C','D','E','F','G','H','I'],
            times:{
                year:['',2006,2007,2008,2009,2010,2011,2012,2013],
                quarter:['','Ⅰ-2006','Ⅱ-2006','Ⅲ-2006','Ⅳ-2006','Ⅰ-2007','Ⅱ-2007','Ⅲ-2007','Ⅳ-2007'],
                month:['','1-2006','2-2006','3-2006','4-2006','5-2016','6-2006','7-2006','8-2006'],
            },
            tableData : [
                ['国内生产总值/增加值（当年价）（亿元）-北京',8117.78,9846.81,11115,12153.53,14113.58,16251.93,17879.4,19800.81],
                ['国内生产总值/增加值（当年价）（亿元）-天津',4462.74,5252.76,6719.01,7521.85,9224.46,11307.28,12893.88,14442.01],
                ['国内生产总值/增加值（当年价）（亿元）-上海',10572.24,12494.01,14069.81,15046.45,17165.98,19195.69,20181.72,218182.15],
                ['人均国内生产总值（元）-北京',50467,58204,63029,70452,73856,81658,87475,94648],
                ['人均国内生产总值（元）-天津',41163,46122,55473,62547,72994,85213,93173,100105],
                ['人均国内生产总值（元）-上海',57695,66367,73124,78989,76074,82560,58373,90993],
                ['第一产业所占比重（%）-北京',2.7,2.2,1.9,1.7,1.6,1.4,1.3309,1.3],
            ]
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.uploadMouldVisible = true;
            }
        }
    },
    methods:{
        beforeUpload(file) {
            if(file.size>1048576) {
                messageTips('文件大小不要超过1M',2);
                return false;
            }            
        },
        handleChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                if(!info.file.response.success) {
                    messageTips(info.file.response.message,2);
                    return false;
                }
                if(info.file.response.existFlag) {
                    var that = this;
                    this.$confirm({
                        title: '提示',
                        content: '同名文件已存在，是否覆盖？',
                        okText:'覆盖',
                        cancelText:'取消',
                        centered:true,
                        onOk() {
                            that.$http.cloud_insert_mydata({freqId:that.freqId}).then(res=>{
                                if(res) {
                                    if(res.success) {
                                        messageTips(info.file.name+'上传成功',3);
                                        that.uploadMouldVisible = false;
                                        that.$emit('uploadSuccess',true);
                                    } else {
                                        messageTips(res.message,1);
                                    }
                                }
                            })               
                        },
                        onCancel() {},
                    });
                } else {
                    this.$http.cloud_insert_mydata({freqId:this.freqId}).then(res=>{
                        if(res) {
                            if(res.success) {
                                messageTips(info.file.name+'上传成功',3);
                                this.uploadMouldVisible = false;
                                this.$emit('uploadSuccess',true);
                            } else {
                                messageTips(res.message,1);
                            }
                        }
                    });
                }
                
            } else if (info.file.status === 'error') {
                messageTips(`${info.file.name} 上传失败。`,1);
            }
        },
    }
}
</script>

<style lang="scss">

.ant-upload-list {
    display: none;
}
</style>
<style lang="scss" scoped>
@import '../../../scss/_variables.scss';
.moudle-win {
    .moudle-name {
        height:32px;
        line-height: 32px;
        .iconfont {
            float:left;
            font-size:30px;
            color:#207245;
            margin-right:3px;
        }
    }

    .excel {
        border:4px solid #8e9d95;
        border-radius:5px;
        width:100%;
        height:270px;
        overflow:hidden;
        background:#e6e6e6;
        table {
            width:100%;
            margin-top:5px;
        }
        thead {
            th{
                border-bottom:1px solid #999999;
                border-right:1px solid #999999;
                background:#e6e6e6;
                height:20px;
                position:relative;
                text-align: center;
                .triangle {
                    display:block;
                    width:10px;
                    height:10px;
                    overflow:hidden;
                    position:absolute;
                    bottom:3px;
                    right:3px;
                }
                .triangle::after {
                    content:'';
                    display:block;
                    width:0;
                    height:0;
                    border-left:10px solid transparent;
                    border-right:10px solid transparent;
                    border-bottom:10px solid #b4b4b4;
                }
            }
        }
        tbody {
            .times {
                td {
                    text-align:center;
                }
                td:nth-child(2) {
                    i{
                        display:none;
                    }
                }
            }
            td {
                text-align:right;
                background:#fff;
                i {
                    display:none;
                }
            }
            td.i-name {
                text-align:left;
            }
            th,td {
                border-bottom:1px solid #d4d4d4;
                border-right:1px solid #d4d4d4;
                height:33px;
                padding:0 5px;
            }
            th {
                border-right:1px solid #999999;
                background:#e6e6e6;
                font-weight:normal;
            }
        }
    }
    .tips {
        padding-top: 10px;
        .red {
            color:#f00;
        }
        .green {
            color:$primary-color;
        }
    }
}
</style>
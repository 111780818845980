<template>
    <a-modal v-model="filterVisible" title="H-P滤波" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="80px"><div class="label">选变量：</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="varIndex">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            
            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            varIndex:null,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.varIndex = null;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(!this.varIndex) {
                messageTips('请选择变量',2);
                return false;
            }
            let params = {
                varIndex:this.varIndex,
                sheetId:this.sheetId
            }
            this.spinning = true;
            this.$http.cloud_set_hp(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.success===false) {
                        messageTips(res.message,1);
                        return false;
                    }
                    this.$emit('refreshData',true);
                    this.filterVisible = false;
                    var result = {'analysis_hp':res}
                    setSessionItem('analysis_hp',JSON.stringify(result));
                    let {href} = this.$router.resolve({path: '/analysis_hp'});
                    window.open(href, '_blank');
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
</style>
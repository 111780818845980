<template>
    <a-modal v-model="filterVisible" title="偏相关" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="60px"><div class="label">变量1</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="var1">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="60px"><div class="label">变量2</div></a-col>
                <a-col flex="auto">
                    <a-select style="width:100%" v-model="var2">
                        <a-select-option v-for="item in list" :key="item.index" :value="item.index">{{item.name}}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <div class="variables">
                <div class="h2">控制变量：</div>
                <div class="list">
                    <a-checkbox-group v-model="checkedList">
                        <div class="check-item" v-for="item in list" :key="item.index">
                            <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
            </div>
            <div class="other">
                <div class="h2">显著性检验：
                    <a-radio-group v-model="sign">
                        <a-radio :value="2" style="font-weight:normal">双侧</a-radio>
                        <a-radio :value="1" style="font-weight:normal">单侧</a-radio>
                    </a-radio-group>
                </div>
            </div>
            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            sign:2,
            var1:null,
            var2:null,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.checkedList = [];
                this.var1 = null;
                this.var2 = null;
                this.sign = 2;
                this.list = [];
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        goApply() {
            if(this.var1===null) {
                messageTips('请选择变量1',2);
                return false;
            }
            if(this.var2===null) {
                messageTips('请选择变量2',2);
                return false;
            }
            if(this.checkedList.length===0) {
                messageTips('请选择控制变量',2);
                return false;
            }
            if(this.var1===this.var2||this.checkedList.indexOf(this.var1)!==-1||this.checkedList.indexOf(this.var2)!==-1) {
                messageTips('三个变量不要有交叉',2);
                return false;
            }
            let params = {
                action:'partial',
                ctrlvars:this.checkedList.join(','),
                sheetId:this.sheetId,
                normalvars:this.var1+','+this.var2,
                sign:this.sign
            }
            this.spinning = true;
            this.$http.cloud_set_correlation(params).then(res=>{      
                this.spinning = false;
                if(res.success===false) {
                    messageTips(res.message,1);
                    return false;
                }
                let result = {'analysis_partial2':res}          
                setSessionItem('analysis_partial2',JSON.stringify(result));
                this.filterVisible = false;
                let {href} = this.$router.resolve({path: '/analysis_partial'});
                window.open(href, '_blank');
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
    .variables {
        margin-top: 20px;
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }

    .other {
        padding-top: 10px;
    }
</style>
<template>
    <a-modal v-model="filterVisible" title="Johansen协整检验" :footer="null" :centered="true">
        <a-spin :spinning="spinning" tip="计算中……">
            <div class="variables">
                <div class="h2">选择变量：<a-checkbox @click="selectAll" :indeterminate="checkedList.length>0&&checkedList.length<list.length" :checked="checkedList.length===list.length&&list.length>0">全选</a-checkbox></div>
                <div class="list">
                    <a-checkbox-group v-model="checkedList">
                        <div class="check-item" v-for="item in list" :key="item.index">
                            <a-checkbox :value="item.index">{{item.name}}</a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
            </div>

            <div class="other">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <div class="box">
                            <div class="name">选择类型</div>
                            <div class="checkbox">
                                <a-radio-group v-model="eigen">
                                    <div class="item">
                                        <a-radio :value="1">最大特征值检测</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="2">特征根迹检测</a-radio>
                                    </div>
                                    <div class="item"></div>
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>

                    <a-col :span="12">
                        <div class="box">
                            <div class="name">模型附加项</div>
                            <div class="checkbox">
                                <a-radio-group v-model="type">
                                    <div class="item">
                                        <a-radio :value="1">无</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="2">截距项</a-radio>
                                    </div>
                                    <div class="item">
                                        <a-radio :value="3">趋势项</a-radio>
                                    </div>
                                </a-radio-group>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div class="other">
                <a-row>
                    <a-col :span="3"><div class="label">滞后阶数</div></a-col>
                    <a-col :span="6">
                        <a-input-number v-model="number" :min="0" />
                    </a-col>
                </a-row>
            </div>

            <div class="btns">
                <a-button type="primary" ghost @click="cancel">取消</a-button>
                <a-button type="primary" @click="goApply">应用</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips, setSessionItem } from '../../../until/tools';
export default {
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            number:5,
            eigen:1,
            type:1,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.checkedList = [];
                this.list = [];
                this.number =5;
                this.eigen = 1;
                this.type = 1;
                this.spinning = false;
                this.$http.cloud_get_variables({action:'regression',sheetId:this.sheetId}).then(res=>{
                    if(res.deptVars) {
                        this.list = res.deptVars;
                    }
                })
                this.filterVisible = true;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },

        selectAll() {
            if(this.checkedList.length === this.list.length) {
                this.checkedList = [];
            } else {
                this.list.forEach(item=>{
                    let index = this.checkedList.indexOf(item.index);
                    if(index===-1) {
                        this.checkedList.push(item.index);
                    }
                });
            }            
        },

        goApply() {
            if(this.checkedList.length===0) {
                messageTips('请选择变量',2);
                return false;
            }
            if(this.checkedList.length===1) {
                messageTips('请选择多个变量',2);
                return false;
            }
            let params = {
                indexs:this.checkedList.join(','),
                lags:this.number,
                eigen:this.eigen,
                type:this.type,
                sheetId:this.sheetId
            }
            this.spinning = true;
            this.$http.cloud_set_johansen(params).then(res=>{
                this.spinning = false;
                if(!res.success) {
                    messageTips(res.message,1);
                    return;
                }
                this.filterVisible = false;
                let result = {'analysis_johansen':res}
                setSessionItem('analysis_johansen',JSON.stringify(result));
                let {href} = this.$router.resolve({path: '/analysis_johansen'});
                window.open(href, '_blank');
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .label {
        font-weight: bold;
        height:32px;
        line-height: 32px;
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
    .other {
        padding-top: 20px;
    }

    .box {
        margin:10px 0;
        border:1px solid #ddd;
        padding:10px 0 10px 10px;
        position: relative;
        .name {
            background:#fff;
            padding:0 10px;
            position: absolute;
            top:-10px;
            left:10px;
        }
        .checkbox {
            padding:10px 0;
            .item {
                height:32px;
                line-height: 32px;
                .item-inner {
                    display: inline-block;
                    width:80px;
                }
            }
        }
    }
</style>